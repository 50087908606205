// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import { lastOperationalEdgeStatus, lastOperationalUnitData } from '../../../../plugins/crusher';

// Types
import { EquipmentUnitDataCrusher } from '../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../types/global';

// Components
import { Thermometer } from '../../../../layout/elements/Thermometer';

// View
import { CavityLevelModuleContainer, ThermometerContainer } from './CavityLevelModule.style';
import { CrusherCavityLevelModuleProps } from './CavityLevelModule.types';

function CavityLevelModule({
  className,
  operationalData,
  testId,
  thresholds,
}: CrusherCavityLevelModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  return (
    <CavityLevelModuleContainer
      data-testid={testId}
      className={className}
    >
      <ThermometerContainer>
        <Thermometer
          className="thermometer"
          minValue={0}
          maxValue={100}
          value={lastUnitData?.cavityLevel}
          unit="%"
          title={`${t('view.crusher.cavityLevel')}`}
          thresholds={thresholds}
          running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
        />
      </ThermometerContainer>
    </CavityLevelModuleContainer>
  );
}

export { CavityLevelModule };
