// Core
import React, { useEffect } from 'react';

// Libraries
import { ButtonType as AntDButtonType } from 'antd/lib/button';
import _ from 'lodash';

// Component
import {
  Element,
  LoadingProgress,
} from './Button.style';
import {
  ButtonColor,
  ButtonProps,
  ButtonSize,
  ButtonType,
} from './Button.types';

function Button({
  block = true,
  children,
  className,
  color = ButtonColor.primary,
  disabled = false,
  loading = null,
  loadingText,
  onClick,
  size = ButtonSize.md,
  square = false,
  testId,
  type = ButtonType.primary,
}: ButtonProps): JSX.Element {
  /* **********************************************************************************************
  **************************************** VALIDATIONS ********************************************
  *********************************************************************************************** */

  if (type === 'primary' && color === 'neutral') {
    throw new Error('Primary type can only have [primary] and [danger] colors');
  }
  if (type === 'secondary' && (color === 'neutral' || color === 'danger')) {
    throw new Error('Secondary type can only have [primary] color');
  }
  if (!_.isNil(loading) && !loadingText) {
    throw new Error('If you use [loading], you need to insert a [loadingText]');
  }
  if (!_.isNil(loading) && loading > 100) {
    throw new Error('Value of [loading] must be less than 100');
  }

  /* **********************************************************************************************
  *************************************** INITIAL STATES ******************************************
  *********************************************************************************************** */

  const [internalLoading, setInternalLoading] = React.useState<number | null>(null);

  /* **********************************************************************************************
  ************************************* COMPONENT HANDLING ****************************************
  *********************************************************************************************** */

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (_.isNil(internalLoading) && onClick) {
      onClick(event);
    }
  };

  // Map ButtonType to AntDButtonType
  const mapTypeToAntD: Record<ButtonType, AntDButtonType> = {
    [ButtonType.secondary]: 'default',
    [ButtonType.primary]: 'primary',
    [ButtonType.link]: 'link',
  };

  useEffect((): void => {
    if (loading === true) { // If loading is true, we will simulate a loading
      if (_.isNil(internalLoading)) {
        setInternalLoading(0);
      } else {
        setTimeout((): void => {
          setInternalLoading((prevLoading: number | null) => {
            let newLoading: number | null = prevLoading;
            if (!_.isNil(prevLoading) && prevLoading !== 100) {
              newLoading = prevLoading + (4 * ((75 - prevLoading) * 0.005));
            }
            return newLoading;
          });
        }, 35);
      }
    } else { // If loading is false, we will stop the loading
      setInternalLoading(loading);
    }
  }, [loading, internalLoading]);

  return (
    <Element
      data-testid={testId}
      className={className}
      color={color}
      loading={_.isNil(internalLoading) ? false : { delay: internalLoading }}
      internalloading={internalLoading}
      type={mapTypeToAntD[type]}
      disabled={disabled}
      block={block && !square}
      onClick={handleClick}
      btnsize={size}
      square={square.toString()}
    >
      <LoadingProgress
        internalLoading={internalLoading}
        loading={loading}
        btnsize={size}
      />
      {!_.isNil(internalLoading) ? loadingText : children}
    </Element>
  );
}

export { Button };
