// Core
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

// Libraries
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// Plugins
import { timestampToDateStrText } from '../../../plugins/general';
import { RequestMethod, requestVantageApi } from '../../../plugins/request';

// Store
import {
  LayoutState,
  setTableConfigs,
  TableColumnConfigs,
  TableConfigs,
  useLayoutStore,
} from '../../../store/layout.store';
import { getUser } from '../../../store/session.store';

// Types
import {
  Customer,
  EquipmentCategory,
  EquipmentModel,
  EquipmentUnit,
  User,
  UserType,
} from '../../../types/global';

// Media
import ImageConveyor from '../../../media/images/equipment/conveyor.png';
import ImageCrusher from '../../../media/images/equipment/crusher.png';
import ImageRoll from '../../../media/images/equipment/roll.png';
import ImageScreen from '../../../media/images/equipment/screen.png';
import ImageWasher from '../../../media/images/equipment/washer.png';

// Components
import { AdminPanel } from '../../../layout/AdminPanel';
import { Breadcrumb } from '../../../layout/elements/Breadcrumb';
import {
  Button,
  ButtonSize,
  ButtonType,
} from '../../../layout/elements/Button';
import { Checkbox } from '../../../layout/elements/Checkbox';
import { Col } from '../../../layout/elements/Col';
import { EmptyBox } from '../../../components/EmptyBox';
import { FilterBar } from '../../../layout/elements/FilterBar';
import {
  Edit,
  Search,
  TrashCan,
  UploadLine,
  View,
} from '../../../layout/icons';
import { Input } from '../../../layout/elements/Input';
import { Loader, LoaderSize } from '../../../layout/elements/Loader';
import { PaginationFooter } from '../../../layout/elements/PaginationFooter';
import {
  OptionsDropdown,
  OptionsDropdownColor,
  OptionsDropdownPlacement,
} from '../../../layout/elements/OptionsDropdown';
import { PageHeader } from '../../../layout/elements/PageHeader';
import { Row } from '../../../layout/elements/Row';
import { Select, SelectSizeType } from '../../../layout/elements/Select';
import { Table, TableColumnProps } from '../../../layout/elements/Table';
import { TableColumnLayout } from '../../../layout/elements/TableColumnLayout';

// View
import {
  ActionBarContainer,
  ActionsContainer,
  BreadCrumbContainer,
  ButtonContainer,
  ColumnTableWithImage,
  CreatedAtContainer,
  EmptyBoxContainer,
  FilterBarContainer,
  InputContainer,
  LoadingContainer,
  SelectContainer,
  SelectCustomerContainer,
  TableContainer,
} from './Inventory.style';
import {
  ColumnId,
  CombinedEquipmentsAndCustomer,
  DrawerOperation,
  InfoEquipmentCategory,
} from './Inventory.types';

// Drawers
import { CreateInventory } from './Drawers/Create/Create';
import { DeleteInventory } from './Drawers/Delete/Delete';
import { ReadInventory } from './Drawers/Read/Read';
import { UpdateInventory } from './Drawers/Update/Update';

function Inventory(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  // Getters
  const user: User | null = getUser();

  // Redirects if the user is not an admin
  if (!user || user.type !== UserType.admin) {
    navigate('/plant-management');
  }

  /* ***********************************************************************************************
  *************************************** LOCAL STATES *********************************************
  *********************************************************************************************** */

  interface InventoryState {
    checkbox: {
      checked: boolean;
      selectedRows: string[];
    };
    customers: Customer[];
    drawerOperation: DrawerOperation;
    equipmentModels: EquipmentModel[];
    equipmentUnits: EquipmentUnit[];
    inputValue?: string | number | null;
    isDrawerOpen: boolean;
    isLoading: boolean;
    pagination: {
      currentPage: number;
      pageSize: number | null;
      totalItems: number;
    };
    selectedEquipmentCategory: string;
    selectedCustomer: Customer | null;
    selectedEquipmentId: string | null;
  }

  /* Initial state */
  const initialState: InventoryState = {
    checkbox: {
      checked: false,
      selectedRows: [],
    },
    customers: [],
    drawerOperation: DrawerOperation.read,
    equipmentModels: [],
    equipmentUnits: [],
    inputValue: null,
    isDrawerOpen: false,
    isLoading: false,
    pagination: {
      currentPage: 1,
      pageSize: null,
      totalItems: 0,
    },
    selectedEquipmentCategory: 'all',
    selectedCustomer: null,
    selectedEquipmentId: null,
  };

  const [state, setState] = useState<InventoryState>(initialState);

  const tableId: string = `inventory-table-${state.selectedEquipmentCategory}`;

  const tableConfigs: TableConfigs = useLayoutStore((
    tableConfigsState: LayoutState,
  ) => tableConfigsState.tablesConfigs[tableId] || { columns: [] });

  /* ***********************************************************************************************
  *********************************************** METHODS ******************************************
  *********************************************************************************************** */

  /* ********** REQUEST ********** */

  const fetchCustomerData = useCallback(async (): Promise<void> => {
    setState((prevState: InventoryState): InventoryState => ({ ...prevState, isLoading: true }));

    try {
      const customersResponse: Customer[] = await requestVantageApi.customer({
        method: RequestMethod.GET,
        path: '/customer',
      });

      setState((prevState: InventoryState): InventoryState => ({
        ...prevState,
        customers: customersResponse,
      }));
    } catch (error: unknown) {
      console.log('Failed to fetch customer', error);
    }

    setState((prevState: InventoryState): InventoryState => ({ ...prevState, isLoading: false }));
  }, []);

  const fetchEquipmentData = useCallback(async (): Promise<void> => {
    setState((prevState: InventoryState): InventoryState => ({ ...prevState, isLoading: true }));

    try {
      if (state.selectedCustomer) {
        const equipmentUnitsRequest: Promise<EquipmentUnit[]> = requestVantageApi.equipmentUnit({
          method: RequestMethod.GET,
          path: '/crusher/inventory',
          params: { idCustomer: state.selectedCustomer.id },
        });

        const equipmentModelsRequest: Promise<EquipmentModel[]> = requestVantageApi.equipmentModel({
          method: RequestMethod.GET,
          path: '/crusher',
          params: { idCustomer: state.selectedCustomer.id },
        });

        const [equipmentUnitsResponse, equipmentModelsResponse] = await Promise.all([
          equipmentUnitsRequest,
          equipmentModelsRequest,
        ]);

        setState((prevState: InventoryState): InventoryState => ({
          ...prevState,
          equipmentUnits: equipmentUnitsResponse,
          equipmentModels: equipmentModelsResponse,
        }));
      }
    } catch (error: unknown) {
      console.error('Failed to fetch equipment', error);
    }

    setState((prevState: InventoryState): InventoryState => ({ ...prevState, isLoading: false }));
  }, [state.selectedCustomer]);

  /* ********** DRAWERS ********** */

  const openDrawer = useCallback((
    drawerOperation: DrawerOperation,
    selectedItemId?: string,
  ): void => {
    setState((prevState: InventoryState): InventoryState => ({
      ...prevState,
      isDrawerOpen: true,
      drawerOperation,
      selectedEquipmentId: selectedItemId || null,
    }));
  }, []);

  const closeDrawer = (): void => {
    setState((prevState: InventoryState): InventoryState => ({
      ...prevState,
      isDrawerOpen: false,
      selectedEquipmentId: null,
      inputValue: null,
    }));
  };

  const updateData = useCallback(async (): Promise<void> => {
    await fetchEquipmentData();
  }, [fetchEquipmentData]);

  const filteredEquipmentUnits: EquipmentUnit = state.equipmentUnits.filter((
    unit: EquipmentUnit,
  ): boolean => unit.id === state.selectedEquipmentId)[0];

  const mountInventoryDrawers: { [key in DrawerOperation]: JSX.Element | null } = {
    create:
      state.selectedCustomer
        ? (
          <CreateInventory
            customer={state.selectedCustomer}
            equipmentModels={state.equipmentModels}
            isDrawerOpen={state.isDrawerOpen}
            onCloseDrawer={closeDrawer}
            onUpdateData={updateData}
            selectedEquipmentCategory={state.selectedEquipmentCategory}
          />
        )
        : null,
    read:
      state.selectedCustomer && filteredEquipmentUnits
        ? (
          <ReadInventory
            customer={state.selectedCustomer}
            isDrawerOpen={state.isDrawerOpen}
            onCloseDrawer={closeDrawer}
            onRead={(
              operation: DrawerOperation,
              id: string | undefined,
            ) => openDrawer(operation, id)}
            equipmentModel={
            state.equipmentModels.filter(({ id }) => id === filteredEquipmentUnits?.idModel)[0]
          }
            equipmentUnit={filteredEquipmentUnits}
          />
        )
        : null,
    update:
      state.selectedCustomer && filteredEquipmentUnits
        ? (
          <UpdateInventory
            customer={state.selectedCustomer}
            equipmentUnit={filteredEquipmentUnits}
            isDrawerOpen={state.isDrawerOpen}
            onCloseDrawer={closeDrawer}
            onUpdateData={updateData}
          />
        )
        : null,
    delete:
      state.selectedCustomer && filteredEquipmentUnits
        ? (
          <DeleteInventory
            customer={state.selectedCustomer}
            equipmentUnit={filteredEquipmentUnits}
            isDrawerOpen={state.isDrawerOpen}
            onCloseDrawer={closeDrawer}
            onUpdateData={updateData}
          />
        )
        : null,
  };

  /* ********** FILTER BAR ********** */

  /* Filters data of each category */
  const getEquipmentUnit = (equipmentUnit: string): EquipmentUnit[] => state.equipmentUnits.filter((
    equipment: EquipmentUnit,
  ): boolean => equipment.equipmentCategory === equipmentUnit);

  const handleEquipmentFilterChange = (selectedEquipmentCategory: string): void => {
    setState((prevState: InventoryState): InventoryState => ({
      ...prevState,
      selectedEquipmentCategory,
    }));
  };

  /* An array of equipment categories used for rendering Filter Bar options */
  const equipmentCategories: InfoEquipmentCategory[] = [
    {
      id: 'all',
      title: `${t('common.allUnits')}`,
      count: state.equipmentUnits.length,
      key: 'all',
    },
    {
      id: EquipmentCategory.roll,
      imgSrc: ImageRoll,
      title: `${t('common.equipmentCategory.roll.plural')}`,
      count: getEquipmentUnit(EquipmentCategory.roll).length,
      key: EquipmentCategory.roll,
    },
    {
      id: EquipmentCategory.crusher,
      imgSrc: ImageCrusher,
      title: `${t('common.equipmentCategory.crusher.plural')}`,
      count: getEquipmentUnit(EquipmentCategory.crusher).length,
      key: EquipmentCategory.crusher,
    },
    {
      id: EquipmentCategory.screen,
      imgSrc: ImageScreen,
      title: `${t('common.equipmentCategory.screen.plural')}`,
      count: getEquipmentUnit(EquipmentCategory.screen).length,
      key: EquipmentCategory.screen,
    },
    {
      id: EquipmentCategory.washer,
      imgSrc: ImageWasher,
      title: `${t('common.equipmentCategory.washer.plural')}`,
      count: getEquipmentUnit(EquipmentCategory.washer).length,
      key: EquipmentCategory.washer,
    },
  ];

  /* ********** SEARCH ********** */

  /**
   * Checks if the equipment unit matches the search value based on visible columns
   *
   * @param {EquipmentUnit} unit - The equipment unit to be checked.
   * @returns {boolean} - Returns `true` if the unit matches the search criteria, otherwise `false`.
   */
  const filterUnitBySearch = (unit: EquipmentUnit): boolean => {
    let matchesSearch: boolean = true;

    if (state.inputValue && tableConfigs) {
      const searchValue: string = state.inputValue.toString().toLowerCase();
      matchesSearch = false;

      tableConfigs.columns.forEach((col: TableColumnConfigs): void => {
        let fieldValue: string | number | null = unit[col.id as keyof EquipmentUnit];

        // Convert createdAt timestamp to date string
        if (col.id === ColumnId.createdAt && typeof fieldValue === 'number') {
          fieldValue = timestampToDateStrText(fieldValue);
        }

        // Check if the field value contains the search value
        if (typeof fieldValue === 'string' && fieldValue.toLowerCase().includes(searchValue)) {
          matchesSearch = true;
        } else if (typeof fieldValue === 'number' && fieldValue.toString().includes(searchValue)) {
          matchesSearch = true;
        }
      });
    }

    return matchesSearch;
  };

  const handleInputChange = useCallback((value?: string | number | null): void => {
    setState((prevState: InventoryState): InventoryState => ({
      ...prevState,
      inputValue: value,
    }));
  }, []);

  /* ********** TABLE ********** */

  /**
   * Filters out deleted equipment units and maps each unit to include additional model and customer
   * information.
   *
   * @returns {CombinedEquipmentsAndCustomer[]}
   */
  const combinedTableData: CombinedEquipmentsAndCustomer[] = state.equipmentUnits
    .map((unit: EquipmentUnit): CombinedEquipmentsAndCustomer => {
      const equipmentModel: EquipmentModel | undefined = state.equipmentModels.find((
        model: EquipmentModel,
      ): boolean => model.id === unit.idModel);

      return {
        ...unit,
        commercialName: equipmentModel ? equipmentModel.commercialName : null,
        superiorPartNumber: equipmentModel ? equipmentModel.superiorPartNumber : null,
        customer: state.selectedCustomer ? state.selectedCustomer.name : null,
        key: unit.id,
      };
    });

  /* Filters equipment data rows based on the selected category and search criteria */
  const filteredEquipmentDataRows: (
    CombinedEquipmentsAndCustomer
  )[] = combinedTableData.filter(filterUnitBySearch)
    .filter((unit: CombinedEquipmentsAndCustomer): CombinedEquipmentsAndCustomer | boolean => (
      state.selectedEquipmentCategory === 'all'
        ? true
        : unit.equipmentCategory === state.selectedEquipmentCategory));

  /* ********** PAGINATION FOOTER ********** */

  const handlePageChange = useCallback((page: number, pageSize: number): void => {
    setState((prevState: InventoryState): InventoryState => ({
      ...prevState,
      pagination: {
        totalItems: filteredEquipmentDataRows.length,
        currentPage: page,
        pageSize,
      },
    }));
  }, [filteredEquipmentDataRows.length]);

  /* ********** CHECKBOX ********** */

  /**
   * Updates state to reflect row selection or deselection, including "Select All" functionality
   *
   * @param {string} [rowId] - The ID of the row that was checked or unchecked.
   */
  const handleCheckboxChange = useCallback((rowId?: string): void => {
    setState((prevState: InventoryState): InventoryState => {
      let updatedSelectedRows: string[];

      if (rowId) {
        // Toggle individual row selection
        updatedSelectedRows = prevState.checkbox.selectedRows.includes(rowId)
          ? prevState.checkbox.selectedRows.filter((id: string): boolean => id !== rowId)
          : [...prevState.checkbox.selectedRows, rowId];
      } else {
        // Toggle all rows selection
        const allRowsSelected: boolean = prevState.checkbox.selectedRows.length
          === filteredEquipmentDataRows.length;
        updatedSelectedRows = allRowsSelected
          ? []
          : filteredEquipmentDataRows.map((row: CombinedEquipmentsAndCustomer): string => row.id);
      }

      return {
        ...prevState,
        checkbox: {
          checked: updatedSelectedRows.length === filteredEquipmentDataRows.length,
          selectedRows: updatedSelectedRows,
        },
      };
    });
  }, [filteredEquipmentDataRows]);

  /* ********** COLUMNS TABLE ********** */

  /* Defines the columns for the inventory table  */
  const columns: TableColumnProps[] = useMemo((): TableColumnProps[] => [
    {
      title: <Checkbox
        checked={state.checkbox.checked}
        onChange={() => handleCheckboxChange()}
        disabled // @TODO - Implement enabled when import ready
        testId="checkbox-title-inventory"
      />,
      dataIndex: ColumnId.checkbox,
      key: ColumnId.checkbox,
      render: (text: string, record: any) => (
        <Checkbox
          testId={`checkbox-row-${record.id}`}
          disabled // @TODO - Implement enabled when import ready
          checked={state.checkbox.selectedRows.includes(record.id)}
          onChange={() => handleCheckboxChange(record.id)}
        />
      ),
    },
    {
      title: `${i18n.t('common.serialNumber')}`,
      dataIndex: ColumnId.serialNumber,
      key: ColumnId.serialNumber,
      sorter: true,
      render: (text: string, record: any) => (
        <ColumnTableWithImage>
          {ImageCrusher && ( // @TODO Load image dynamically from backend in future
            <img
              src={ImageCrusher}
              alt={`${i18n.t(`common.${record.equipmentCategory}`)}`}
            />
          )}
          {text}
        </ColumnTableWithImage>
      ),
    },
    {
      title: `${i18n.t('common.category')}`,
      dataIndex: ColumnId.equipmentCategory,
      key: ColumnId.equipmentCategory,
      sorter: true,
      render: (text: string): string => {
        switch (text) {
          case EquipmentCategory.crusher:
            return `${i18n.t('common.equipmentCategory.crusher.singular')}`;
          case EquipmentCategory.roll:
            return `${i18n.t('common.equipmentCategory.roll.singular')}`;
          case EquipmentCategory.screen:
            return `${i18n.t('common.equipmentCategory.screen.singular')}`;
          case EquipmentCategory.washer:
            return `${i18n.t('common.equipmentCategory.washer.singular')}`;
          default:
            return text;
        }
      },
    },
    {
      title: `${i18n.t('common.superiorPartNumber')}`,
      dataIndex: ColumnId.superiorPartNumber,
      key: ColumnId.superiorPartNumber,
      sorter: true,
    },
    {
      title: `${i18n.t('common.customerPartNumber')}`,
      dataIndex: ColumnId.customerPartNumber,
      key: ColumnId.customerPartNumber,
      sorter: true,
    },
    {
      title: `${i18n.t('common.commercialName')}`,
      dataIndex: ColumnId.commercialName,
      key: ColumnId.commercialName,
      sorter: true,
    },
    {
      title: `${i18n.t('common.status')}`,
      dataIndex: ColumnId.installStatus,
      key: ColumnId.installStatus,
      sorter: true,
      render: (text: string): string => {
        switch (text) {
          case 'pre':
            return `${i18n.t('equipment.unit.installStatus.preRegistered')}`;
          case 'installed':
            return `${i18n.t('equipment.unit.installStatus.installed')}`;
          case 'removed':
            return `${i18n.t('equipment.unit.installStatus.removed')}`;
          default:
            return text;
        }
      },
    },
    {
      title: `${i18n.t('common.customer')}`,
      dataIndex: ColumnId.customer,
      key: ColumnId.customer,
      sorter: true,
    },
    {
      title: `${i18n.t('common.createdAt')}`,
      dataIndex: ColumnId.createdAt,
      key: ColumnId.createdAt,
      sorter: true,
      render: (text: string, record): JSX.Element => {
        const { createdAt } = record;
        return (
          <CreatedAtContainer>
            {timestampToDateStrText(createdAt)}
          </CreatedAtContainer>
        );
      },
    },
    {
      dataIndex: ColumnId.dropdown,
      key: ColumnId.dropdown,
      sorter: false,
      render: (text: string, record: any) => (
        <OptionsDropdown
          testId="options-dropdown-inventory"
          placement={OptionsDropdownPlacement.bottomRight}
          options={[
            {
              text: `${i18n.t('common.view')}`,
              icon: <View />,
              color: OptionsDropdownColor.normal,
              disabled: false,
              onClick: () => openDrawer(DrawerOperation.read, record.id),
            },
            {
              text: `${i18n.t('common.edit')}`,
              icon: <Edit />,
              color: OptionsDropdownColor.normal,
              disabled: false,
              onClick: () => openDrawer(DrawerOperation.update, record.id),
            },
          ]}
          secondaryOptions={[
            {
              text: `${i18n.t('common.delete')}`,
              icon: <TrashCan />,
              color: OptionsDropdownColor.danger,
              disabled: false,
              onClick: () => openDrawer(DrawerOperation.delete, record.id),
            },
          ]}
        />
      ),
    },
  ], [
    state.checkbox.checked,
    state.checkbox.selectedRows,
    handleCheckboxChange,
    openDrawer,
  ]);

  /* ********** TABLE COLUMN LAYOUT ********** */

  /* Memoized function to get the visible columns for the table */
  const visibleColumns: TableColumnProps[] = useMemo((): TableColumnProps[] => {
    let resultColumns: TableColumnProps[] = [];

    /* Checks if a column is visible based on the table configuration */
    const isColumnVisible = (columnKey: string) => tableConfigs.columns.some((
      config: TableColumnConfigs,
    ): boolean => config.id === columnKey && config.visible);

    // If no columns are configured, set all columns to visible
    if (!tableConfigs.columns || tableConfigs.columns.length === 0) {
      setTableConfigs({
        id: tableId,
        columns: columns.map((column: TableColumnProps): {
          id: string,
          visible: boolean,
        } => ({
          id: column.key,
          visible: true,
        })),
      });
      resultColumns = columns;
    } else {
      // Filter the columns based on the visibility settings
      resultColumns = columns.filter((column: TableColumnProps) => isColumnVisible(column.key));
    }

    return resultColumns;
  }, [
    columns,
    tableId,
    tableConfigs,
  ]);

  /* Toggles the visibility of table columns based on the selected columns */
  const toggleColumnVisibility = useCallback((
    selected: string | number | (string | number)[] | null,
  ): void => {
    // Map through the columns and set the visibility based on the selected columns
    const newColumns: (TableColumnProps & { visible: boolean })[] = columns.map((
      column: TableColumnProps,
    ): TableColumnProps & { visible: boolean } => ({
      ...column,
      visible: Array.isArray(selected) && selected.includes(column.key),
    }));

    // Update the table configurations with the new column visibility settings
    setTableConfigs({
      id: tableId,
      columns: newColumns.map((
        column: TableColumnProps & { visible: boolean },
      ): { id: string, visible: boolean } => ({ id: column.key, visible: column.visible })),
    });
  }, [columns, tableId]);

  // Define a function to handle the column label logic
  const getColumnLabel = (key: string, title: string | JSX.Element | undefined): string => {
    const labels: { [key: string]: string } = {
      [ColumnId.checkbox]: t('common.checkbox'),
      [ColumnId.commercialName]: t('common.commercialName'),
      [ColumnId.createdAt]: t('common.createdAt'),
      [ColumnId.customer]: t('common.customer'),
      [ColumnId.customerPartNumber]: t('common.customerPartNumber'),
      [ColumnId.dropdown]: t('common.optionsDropdown'),
      [ColumnId.equipmentCategory]: t('common.equipmentCategory'),
      [ColumnId.installStatus]: t('common.installStatus'),
      [ColumnId.serialNumber]: t('common.serialNumber'),
      [ColumnId.superiorPartNumber]: t('common.superiorPartNumber'),
    };

    // If column title is not provided, return the key as the label
    return typeof title === 'string' ? title : labels[key];
  };

  /* ********** SELECT ********** */

  /* Updates state with the selected customer based on the provided ID */
  const handleCustomerChange = (value: string | string[]): void => {
    const selectedCustomer: Customer | undefined = state.customers.find(
      (selectCustomer: Customer): boolean => selectCustomer.id === value,
    );

    if (selectedCustomer) {
      setState((prevState: InventoryState): InventoryState => ({
        ...prevState,
        selectedCustomer,
      }));
    }
  };

  /* ***********************************************************************************************
  ************************************ COMPONENT HANDLING ******************************************
  *********************************************************************************************** */

  useEffect((): void => {
    fetchCustomerData().catch((): void => {
      setState((prevState: InventoryState): InventoryState => ({ ...prevState, isLoading: false }));
    });
  }, [fetchCustomerData]);

  useEffect((): void => {
    fetchEquipmentData().catch((): void => {
      setState((prevState: InventoryState): InventoryState => ({ ...prevState, isLoading: false }));
    });
  }, [fetchEquipmentData]);

  /* Resets the current page to 1 if it exceeds the total number of pages */
  useEffect((): void => {
    if (state.pagination.currentPage > Math.ceil(
      filteredEquipmentDataRows.length / (state.pagination.pageSize as number),
    )) {
      setState((prevState: InventoryState): InventoryState => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          currentPage: 1,
        },
      }));
    }
  }, [
    filteredEquipmentDataRows.length,
    state.pagination.currentPage,
    state.pagination.pageSize,
  ]);

  useEffect((): void => {
    if (state.selectedEquipmentCategory !== 'all') {
      setState((prevState: InventoryState): InventoryState => ({
        ...prevState,
        selectedEquipmentCategory: state.selectedEquipmentCategory,
      }));
    }
  }, [state.selectedEquipmentCategory]);

  return (
    <AdminPanel>
      {state.isLoading ? (
        <LoadingContainer>
          <Loader text={`${t('common.loaderInfo')}`} size={LoaderSize.xl} />
        </LoadingContainer>
      ) : (
        <>
          <BreadCrumbContainer>
            <Breadcrumb
              testId="breadcrumb-inventory"
              items={[
                { target: '/inventory', text: `${t('common.inventory')}` },
              ]}
            />
          </BreadCrumbContainer>

          <SelectCustomerContainer>
            <Row>
              <Col cols={17}>
                <PageHeader
                  testId="page-header"
                  title={`${t('common.inventory')}`}
                  description={`${t('view.inventory.headerDescription')}`}
                />
              </Col>

              <Col cols={7}>
                <SelectContainer>
                  <Select
                    testId="customer-select-inventory"
                    value={state.selectedCustomer?.id}
                    size={SelectSizeType.lg}
                    placeholder={`${t('view.inventory.selectCustomer')}`}
                    items={state.customers.map((
                      selectedCustomer: Customer,
                    ): { id: string, text: string } => ({
                      id: selectedCustomer.id,
                      text: selectedCustomer.name,
                    }))}
                    onChange={handleCustomerChange}
                    allowClear={false}
                    label={`${t('view.inventory.selectCustomer')}`}
                  />
                </SelectContainer>
              </Col>
            </Row>
          </SelectCustomerContainer>

          <FilterBarContainer>
            <FilterBar
              testId="filter-bar-inventory"
              filters={equipmentCategories}
              onClick={handleEquipmentFilterChange}
              selected={state.selectedEquipmentCategory}
            />
          </FilterBarContainer>

          {!state.selectedCustomer ? (
            <EmptyBoxContainer>
              <EmptyBox
                imgSrc={ImageConveyor}
                title={`${t('view.inventory.selectCustomer')}`}
                description={`${t('view.inventory.emptyBoxDescription')}`}
              />
            </EmptyBoxContainer>
          ) : (
            <ActionsContainer>
              <ActionBarContainer>
                <InputContainer>
                  <Input
                    testId="search-input-inventory"
                    value={state.inputValue}
                    placeholder={`${t('view.inventory.inputInfo')}`}
                    onChange={(
                      inputValue: string | number | null | undefined,
                    ) => handleInputChange(inputValue)}
                    label={`${t('view.inventory.inputInfo')}`}
                    prefix={<Search />}
                    maxLength={65}
                  />
                </InputContainer>

                <ButtonContainer>
                  {state.selectedEquipmentCategory !== 'all' && (
                    <TableColumnLayout
                      testId="table-column-layout-inventory"
                      options={columns.map((
                        column: TableColumnProps,
                      ): { key: string, text: string } => ({
                        key: column.key,
                        text: getColumnLabel(column.key, column.title),
                      }))}
                      onSelect={(
                        selected: string | number | (string | number)[] | null,
                      ) => toggleColumnVisibility(selected)}
                      selected={tableConfigs.columns.filter(
                        (col: TableColumnConfigs) => col.visible,
                      ).map((col: TableColumnConfigs) => col.id) || []}
                    />
                  )}
                  <Button
                    size={ButtonSize.xs}
                    onClick={(): void => {}} // @TODO - add method export backend
                    type={ButtonType.secondary}
                    disabled
                    block
                    square
                  >
                    <UploadLine />
                  </Button>
                  <Button
                    testId="pre-register-inventory-button"
                    size={ButtonSize.xs}
                    onClick={(): void => { openDrawer(DrawerOperation.create); }}
                    block={false}
                    disabled={
                      state.selectedEquipmentCategory !== 'all'
                      && state.selectedEquipmentCategory !== EquipmentCategory.crusher
                    }
                  >
                    {t('view.inventory.preRegister')}
                  </Button>
                </ButtonContainer>
              </ActionBarContainer>

              <TableContainer>
                <Table
                  testId="inventory-table"
                  rows={filteredEquipmentDataRows}
                  columns={visibleColumns}
                  pagination={{
                    current: state.pagination.currentPage,
                    pageSize: state.pagination.pageSize as number,
                    totalItems: filteredEquipmentDataRows.length,
                    onPageChange: handlePageChange,
                  }}
                />
              </TableContainer>

              <PaginationFooter
                testId="pagination-footer-inventory"
                totalItems={state.pagination.totalItems}
                onPageChange={handlePageChange}
                currentPage={state.pagination.currentPage}
              />
            </ActionsContainer>
          )}

          {/* DRAWER OPERATIONS */}
          {mountInventoryDrawers[state.drawerOperation]}
        </>
      )}
    </AdminPanel>
  );
}

export { Inventory };
