// Core
import React from 'react';

// Libraries
import { BreadcrumbItemType } from 'antd/lib/breadcrumb/Breadcrumb';

// Layout
import { CaretLeft } from '../../icons';

// Component
import {
  Container,
  Element,
} from './Breadcrumb.style';
import {
  BreadcrumbItem,
  BreadcrumbProps,
  BreadcrumbType,
} from './Breadcrumb.types';

function Breadcrumb({
  className,
  items,
  testId,
  type = BreadcrumbType.page,
}: BreadcrumbProps): JSX.Element {
  const currentPath: string = window.location.pathname;

  // Get the previous target
  const previousTarget: string | undefined = items.length > 1
    ? items[items.length - 2].target
    : '/';

  // Map items to Ant Design BreadcrumbItemType
  const mapItemsToAntD: Pick<BreadcrumbItemType, 'href' | 'title' | 'onClick'>[] = items.map(
    (item: BreadcrumbItem) => ({
      href: item.target,
      title: item.text,
      onClick: (e: React.MouseEvent<HTMLAnchorElement>): void => {
        if (item.target === currentPath) {
          e.preventDefault();
        }
      },
    }),
  );

  // Add the previous target to the breadcrumb items
  const breadcrumbItems: BreadcrumbItemType[] = type === BreadcrumbType.page
    ? [{ href: previousTarget, title: <CaretLeft /> }, ...mapItemsToAntD]
    : mapItemsToAntD;

  return (
    <Container
      data-testid={testId}
      className={className}
    >
      <Element
        data-testid="antd-breadcrumb"
        type={type}
        items={breadcrumbItems}
      />
    </Container>
  );
}

export { Breadcrumb };
