// Core
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

// Libraries
import { useTranslation } from 'react-i18next';

// Types
import {
  EquipmentCategory,
  EquipmentModel,
  EquipmentModelParameter,
} from '../../../../types/global';

// Locale
import i18n from '../../../../locale/i18n';

// Plugins
import { RequestMethod, requestVantageApi } from '../../../../plugins/request';

// Components
import { Col } from '../../../../layout/elements/Col';
import { Drawer } from '../../../../layout/elements/Drawer';
// import { FileUpload } from '../../../../layout/elements/FileUpload';
import { Input, InputType } from '../../../../layout/elements/Input';
import { Paragraph, ParagraphWeight } from '../../../../layout/elements/Paragraph';
import { Row } from '../../../../layout/elements/Row';
import { showToast, ToastType } from '../../../../layout/elements/Toast';

// View
import { EquipmentModelUpdateProps } from './EquipmentModelUpdate.types';
import { SectionContainer } from './EquipmentModelUpdate.style';

function EquipmentModelUpdate({
  equipmentModel,
  isDrawerOpen,
  onCloseDrawer,
  testId,
  updateEquipmentModels,
}: EquipmentModelUpdateProps): JSX.Element | null {
  // Dependencies
  const { equipmentCategory } = useParams<{ equipmentCategory: EquipmentCategory }>();
  const { t } = useTranslation();

  /* *********************************************************************************************
  **************************************** INITIAL STATE ****************************************
  * ******************************************************************************************* */

  // Interfaces
  interface State {
    localEquipmentModel: EquipmentModel | null;
    isUpdateInProgress: true | null | number;
  }

  const parametersByCategory = useMemo(() => ({
    [EquipmentCategory.crusher]: [
      { name: 'headDiameter', unit: 'mm' },
      { name: 'weight', unit: 'kg' },
      { name: 'recommendedHP', unit: 'HP' },
      { name: 'maxFeedOpening', unit: 'mm' },
      { name: 'csS', unit: 'mm' },
    ],
    [EquipmentCategory.roll]: [],
    [EquipmentCategory.screen]: [],
    [EquipmentCategory.washer]: [],
  }), []);

  const initialState: State = {
    localEquipmentModel: null,
    isUpdateInProgress: null,
  };

  const [state, setState] = useState<State>(initialState);

  /* *********************************************************************************************
  ******************************************* METHODS *******************************************
  * ******************************************************************************************* */

  const handleInputChange = useCallback(
    (field: keyof EquipmentModel, value: string | number): void => {
      setState((prevState: State) => ({
        ...prevState,
        localEquipmentModel: prevState.localEquipmentModel
          ? { ...prevState.localEquipmentModel, [field]: value }
          : null,
      }));
    },
    [],
  );

  const handleParamChange = useCallback(
    (paramName: string, value: number): void => {
      setState((prevState: State) => {
        const { localEquipmentModel } = prevState;

        if (!localEquipmentModel) return prevState;

        const updatedParameters: EquipmentModelParameter[] = [
          ...(localEquipmentModel.parameters?.filter((param) => param.name !== paramName) || []),
          { name: paramName, value },
        ];

        return {
          ...prevState,
          localEquipmentModel: {
            ...localEquipmentModel,
            parameters: updatedParameters,
          },
        };
      });
    },
    [],
  );

  const startDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isUpdateInProgress: true,
    }));
  };

  const stopDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isUpdateInProgress: 100,
    }));
  };

  const handleClose = useCallback((): void => {
    onCloseDrawer();
  }, [onCloseDrawer]);

  const handleUpdate = async (
    equipmentModelToUpdate: EquipmentModel,
  ): Promise<void> => {
    startDrawerLoading();

    try {
      await requestVantageApi.equipmentModel({
        method: RequestMethod.PUT,
        path: `/${equipmentCategory}/${equipmentModelToUpdate.id}`,
        body: equipmentModelToUpdate,
      });
      updateEquipmentModels();
      showToast({
        text: i18n.t(
          'view.equipmentModel.update.toastEquipmentUpdated',
          { model: i18n.t(`common.equipmentCategory.${equipmentCategory}.singular`) },
        ),
        type: ToastType.success,
      });
    } catch (error: unknown) {
      console.error('Failed to update equipment model', error);
    } finally {
      stopDrawerLoading();

      setTimeout((): void => {
        setState((prevState) => ({ ...prevState, isUpdateInProgress: null }));
        handleClose();
      }, 300);
    }
  };

  /* **********************************************************************************************
  ************************************** COMPONENT HANDLING ***************************************
  * ******************************************************************************************** */

  const renderParameters = useCallback((category: EquipmentCategory): JSX.Element[] => {
    if (!state.localEquipmentModel || !state.localEquipmentModel.parameters) {
      return [];
    }

    const parameters = parametersByCategory[category];

    return parameters.map((param) => (
      <Row key={param.name}>
        <Col>
          <Input
            onChange={
              (e: string | number | null | undefined) => {
                handleParamChange(param.name, e as number);
              }
            }
            label={`${i18n.t(`equipment.${category}.parameters.${param.name}`)}`}
            type={InputType.number}
            placeholder={
              `${i18n.t(
                'view.equipmentModel.update.inputPlaceholder',
                { parameter: i18n.t(`equipment.${category}.parameters.${param.name}`) },
              )}`
            }
            testId={`input-${param.name}`}
            suffix={param.unit}
            value={
              state.localEquipmentModel?.parameters.find((
                p: EquipmentModelParameter,
              ): boolean => p.name === param.name)?.value
            }
          />
        </Col>
      </Row>
    ));
  }, [
    handleParamChange,
    parametersByCategory,
    state.localEquipmentModel,
  ]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      localEquipmentModel: equipmentModel || null,
    }));
  }, [equipmentModel]);

  return (
    !equipmentCategory || !state.localEquipmentModel
      ? null
      : (
        <Drawer
          testId={testId}
          open={isDrawerOpen}
          title={t('view.equipmentModel.update.titleDrawer', {
            model: t(`common.equipmentCategory.${equipmentCategory}.singular`),
          })}
          onClose={handleClose}
          primaryAction={{
            onClick: () => handleUpdate(state.localEquipmentModel as EquipmentModel),
            children: t('common.update'),
            loading: state.isUpdateInProgress,
            loadingText: `${t('common.updating')}`,
          }}
          secondaryAction={{
            onClick: handleClose,
            children: t('common.cancel'),
          }}
        >
          <Row>
            <Col>
              {/* <SectionContainer> */}
              {/*  <Row> */}
              {/*    <Col> */}
              {/*      <Paragraph weight={ParagraphWeight.bold}>{t('common.image')}</Paragraph> */}
              {/*    </Col> */}
              {/*  </Row> */}
              {/*  <Row> */}
              {/*    <Col> */}
              {/*      <FileUpload */}
              {/*        onUpload={() => {}} */}
              {/*        file={{ source: undefined, fileName: undefined }} */}
              {/*        id="equipment-model-update" */}
              {/*        onRemove={() => {}} */}
              {/*        disabled */}
              {/*        supportingText=".jpg .png .gif .jpeg (Max 1MB)" */}
              {/*      /> */}
              {/*    </Col> */}
              {/*  </Row> */}
              {/* </SectionContainer> */}
              <SectionContainer>
                <Row>
                  <Col>
                    <Paragraph weight={ParagraphWeight.bold}>{t('common.mainInfo')}</Paragraph>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      onChange={
                        (e: string | number | null | undefined) => {
                          handleInputChange('commercialName', e as string);
                        }
                      }
                      label={`${t('common.commercialName')}`}
                      maxLength={65}
                      placeholder={`${t('common.commercialName')}`}
                      value={state.localEquipmentModel.commercialName}
                    />
                  </Col>
                </Row>
              </SectionContainer>
              <SectionContainer>
                <Row>
                  <Col>
                    <Paragraph weight={ParagraphWeight.bold}>{t('common.parameters')}</Paragraph>
                  </Col>
                </Row>
                {renderParameters(equipmentCategory)}
              </SectionContainer>
            </Col>
          </Row>
        </Drawer>
      )
  );
}

export { EquipmentModelUpdate };
