// Core
import styled from 'styled-components';

export const DeleteWarningBoxContainer = styled.div`
  padding: 24px 0;
`;

export const DeleteActionContainer = styled.div`
  padding: 12px 0;

  strong {
    font-family: ${({ theme }) => theme.font.bold};
  };
`;

export const InputContainer = styled.div`
  padding-top: 20px;
`;
