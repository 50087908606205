// Core
import React from 'react';

function Icon() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.3334C5.39746 18.3334 1.66659 14.6026 1.66659 10C1.66659 5.39747 5.39746 1.6666 10 1.6666C14.6025 1.6666 18.3334 5.39747 18.3334 10C18.3334 14.6026 14.6025 18.3334 10 18.3334ZM10 16.6667C11.7681 16.6667 13.4638 15.9644 14.7141 14.7141C15.9643 13.4638 16.6667 11.7681 16.6667 10C16.6667 8.23188 15.9643 6.53617 14.7141 5.28592C13.4638 4.03566 11.7681 3.33328 10 3.33328C8.23188 3.33328 6.53617 4.03566 5.28591 5.28592C4.03566 6.53617 3.33327 8.23188 3.33327 10C3.33327 11.7681 4.03566 13.4638 5.28591 14.7141C6.53617 15.9644 8.23188 16.6667 10 16.6667ZM9.16666 5.8333H10.8333V7.49999H9.16666V5.8333ZM9.16666 9.16667H10.8333V14.1667H9.16666V9.16667Z"
        stroke="currentColor"
        fill="currentColor"
        strokeWidth={0.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
