// Core
import React from 'react';

// Libraries
import { Menu as MenuAntD } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';

// Store
import {
  getUser,
  setAuthToken,
  setCustomer,
  setUser,
} from '../../store/session.store';

// Types
import { User, UserType } from '../../types/global';

// Components
import { Col } from '../elements/Col';
import { Row } from '../elements/Row';
import { Input } from '../elements/Input';
import { LinkTo } from '../elements/LinkTo';
import { Logo, LogoSize } from '../elements/Logo';
import {
  Options,
  OptionsDropdown,
  OptionsDropdownColor,
  OptionsDropdownPlacement,
} from '../elements/OptionsDropdown';
import {
  Account,
  BackToCustomer,
  CaretDown,
  LogoutBox,
  Search,
  Settings,
} from '../icons';

// Component
import {
  NavigationContextType,
  MainNavigationProps,
} from './MainNavigation.types';
import {
  Actions,
  AvatarContainer,
  CaretDownContainer,
  Layout,
  LeftSection,
  OptionsDropdownContainer,
  RightSection,
  SearchContainer,
  UserActions,
} from './MainNavigation.style';

function MainNavigation({
  className,
  context = NavigationContextType.customer,
  searchComponentEnabled = false,
  testId,
}: MainNavigationProps) {
  // Dependencies
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  /* *********************************************************************************************
  ****************************************** METHODS *********************************************
  ********************************************************************************************* */

  type Item = {
    key: string;
    label: string;
  };

  // Get user
  const user: User | null = getUser();

  // Function of logout
  const logoutUser = (): void => {
    setAuthToken(null);
    setUser(null);
    setCustomer(null);
    navigate('/');
  };

  const options: Options[] = [
    {
      text: t('common.settings'),
      icon: <Settings />,
      color: OptionsDropdownColor.normal,
      disabled: false,
      onClick: () => {}, // @TODO implement settings after first release
    },
    ...(user?.type === UserType.admin && location.pathname !== '/' ? [{
      text: t('common.customers'),
      icon: <BackToCustomer />,
      color: OptionsDropdownColor.normal,
      disabled: false,
      onClick: () => navigate('/'),
    }] : []),
    {
      text: t('common.logout'),
      icon: <LogoutBox />,
      color: OptionsDropdownColor.normal,
      disabled: false,
      onClick: logoutUser,
    },
  ];

  // Context-based navigation items
  const items: Item[] = context === NavigationContextType.admin
    ? [
      { key: '/', label: t('common.customers') },
      { key: '/equipment-models', label: t('common.equipmentModel.plural') },
      { key: '/inventory', label: t('common.inventory') },
      { key: '/admin-actions', label: t('common.admin.singular') },
    ]
    : [
      { key: '/plant-management', label: t('common.plantManagement') },
    ];

  // Convert selected key
  const convertSelectedKeyToAntD = (path: string): string => {
    const foundItem: Item | undefined = items.find((item: { key: string; }) => path === item.key);
    return foundItem ? foundItem.key : '';
  };

  // Function of menu redirect
  const menuRedirectToPath = (path: string): void => {
    navigate(path);
  };

  return (
    <Layout
      data-testid={testId}
      className={className}
    >
      <Row>
        <Col cols={7}>
          <LeftSection>
            <LinkTo target="/">
              <Logo size={LogoSize.xs} />
            </LinkTo>
            {
              searchComponentEnabled && (
                <SearchContainer>
                  <Input // @TODO apply search functionality later
                    onChange={(value: string | number | null | undefined) => (value as string)}
                    value=""
                    placeholder={`${t('layout.mainNavigation.search')}`}
                    prefix={<Search />}
                  />
                </SearchContainer>
              )
            }
          </LeftSection>
        </Col>
        <Col cols={17}>
          <RightSection>
            <Actions>
              <MenuAntD
                mode="horizontal"
                items={items}
                onClick={(e) => menuRedirectToPath(e.key)}
                selectedKeys={[convertSelectedKeyToAntD(location.pathname)]}
                disabledOverflow
              />
              <UserActions>
                {/* <BellIcon> */}
                {/*  <Bell /> @TODO implement bell later  */}
                {/* </BellIcon> */}
                <OptionsDropdownContainer>
                  <OptionsDropdown
                    options={options}
                    placement={OptionsDropdownPlacement.bottomLeft}
                    className="options-dropdown"
                  >
                    <AvatarContainer>
                      <Account />
                    </AvatarContainer>
                    <CaretDownContainer>
                      <CaretDown />
                    </CaretDownContainer>
                  </OptionsDropdown>
                </OptionsDropdownContainer>
              </UserActions>
            </Actions>
          </RightSection>
        </Col>
      </Row>
    </Layout>
  );
}

export { MainNavigation };
