// Core
import React, { useState } from 'react';

// Libraries
import { Trans, useTranslation } from 'react-i18next';

// Plugins
import { RequestMethod, requestVantageApi } from '../../../../../plugins/request';

// Components
import { Col } from '../../../../../layout/elements/Col';
import { DeleteWarningBox } from '../../../../../components/DeleteWarningBox';
import { Drawer } from '../../../../../layout/elements/Drawer';
import { Input } from '../../../../../layout/elements/Input';
import { Paragraph } from '../../../../../layout/elements/Paragraph';
import { Row } from '../../../../../layout/elements/Row';
import { showToast, ToastType } from '../../../../../layout/elements/Toast';

// View
import { DeleteCustomerProps } from './Delete.types';
import { DeleteSection } from './Delete.style';

function DeleteCustomer({
  className,
  customer,
  isDrawerOpen,
  onCloseDrawer,
  onUpdateData,
  testId,
}: DeleteCustomerProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  /* ***********************************************************************************************
  *************************************** LOCAL STATES *********************************************
  *********************************************************************************************** */

  interface State {
    isLoading: number | true | null;
    delete?: string | number | null;
  }

  const initialState: State = {
    isLoading: null,
    delete: null,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  *********************************************** METHODS ******************************************
  *********************************************************************************************** */

  const startDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: true,
    }));
  };

  const stopDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: 100,
    }));
  };

  /* Request */
  const onDelete = async (): Promise<void> => {
    startDrawerLoading();

    try {
      await requestVantageApi.customer({
        method: RequestMethod.DELETE,
        path: `/customer/${customer.id}`,
      });

      showToast({
        text: t('view.customers.delete.customerDeleted', { customer: customer.name }),
        type: ToastType.success,
      });

      stopDrawerLoading();

      setTimeout((): void => {
        onUpdateData();
        onCloseDrawer();
      }, 300);
    } catch (error: unknown) {
      onCloseDrawer();
    }
  };

  return (
    <Drawer
      testId={testId}
      className={className}
      title={`${t('view.customers.delete.drawerTitle')}`}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      primaryAction={{
        children: t('common.delete'),
        onClick: onDelete,
        disabled: state.delete !== t('common.delete').toUpperCase(),
        loading: state.isLoading,
        loadingText: `${t('common.deleting')}`,
      }}
      secondaryAction={{ children: t('common.cancel'), onClick: onCloseDrawer }}
    >
      <Row>
        <Col>
          <DeleteWarningBox
            itemIdentifier={{ key: t('common.name'), value: customer.name }}
            itemType={`${t('common.customer').toLowerCase()}`}
            testId="delete-customer-warning"
          />
        </Col>
        <Col>
          <DeleteSection>
            <Paragraph>
              <Trans>
                {t('view.customers.delete.deleteSection')}
              </Trans>
            </Paragraph>
          </DeleteSection>
        </Col>
        <Col>
          <Input
            value={state.delete}
            onChange={(value): void => setState({ ...state, delete: value })}
            placeholder={`${t('view.customers.delete.deletePlaceholder')}`}
            label={`${t('common.delete').toUpperCase()}`}
            testId="delete-customer-confirmation"
          />
        </Col>
      </Row>
    </Drawer>
  );
}

export { DeleteCustomer };
