// Core
import styled from 'styled-components';

export const MainInfoContainer = styled.div`
  padding: 16px 0 20px 0;
`;

export const SectionTitleContainer = styled.div`
  padding-bottom: 12px;
`;
