// Core
import React from 'react';

function Icon() {
  return (
    <svg
      data-testid="plus-icon"
      width="1em"
      height="1em"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.92454 9.1804V4.25977H11.5648V9.1804H16.4854V10.8206H11.5648V15.7412H9.92454V10.8206H5.00391V9.1804H9.92454Z"
        stroke="currentColor"
      />
    </svg>
  );
}

export default Icon;
