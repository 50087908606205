// Libraries
import create from 'zustand';
import { persist } from 'zustand/middleware';

// Types
import { Customer, User } from '../types/global';

export interface SessionState {
  authToken: string | null;
  customer: Customer | null;
  user: User | null;
}

export const useSessionStore = create(
  persist<SessionState>(
    () => ({
      authToken: null,
      customer: null,
      user: null,
    }),
    {
      name: 'session-store',
      getStorage: () => sessionStorage,
    },
  ),
);

// Getters
export const getAuthToken = (): string | null => useSessionStore.getState().authToken;
export const getCustomer = (): Customer | null => useSessionStore.getState().customer;
export const getUser = (): User | null => useSessionStore.getState().user;

// Setters
export const setAuthToken = (authToken: string | null): void => useSessionStore.setState({
  authToken,
});
export const setCustomer = (customer: Customer | null): void => useSessionStore.setState({
  customer,
});
export const setUser = (user: User | null): void => useSessionStore.setState({ user });
