// Libraries
import create from 'zustand';
import { persist } from 'zustand/middleware';

// Types
import { CustomerStats } from '../types/global';

export interface CustomersStatsDetails {
  id: string;
  stats: CustomerStats;
  lastFetched: number;
}

interface CustomersStatsStore {
  customersStats: Record<string, CustomersStatsDetails>;
}

export const useCustomersStatsStore = create(
  persist<CustomersStatsStore>(
    () => ({
      customersStats: {},
    }),
    {
      name: 'customers-stats-store',
      getStorage: () => localStorage,
    },
  ),
);

// Getters
export const getCustomersStats = (id: string): CustomersStatsDetails => {
  const state: CustomersStatsStore = useCustomersStatsStore.getState();
  return (
    state.customersStats[id] || {
      id,
      stats: {},
      lastFetched: null,
    }
  );
};

// Setters
export const setCustomersStats = (id: string, stats: CustomerStats): void => {
  useCustomersStatsStore.setState((state: CustomersStatsStore): CustomersStatsStore => ({
    customersStats: {
      ...state.customersStats,
      [id]: {
        id,
        stats,
        lastFetched: Date.now(),
      },
    },
  }));
};
