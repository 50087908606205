// Core
import styled from 'styled-components';

export const MainInfoContainer = styled.div`
  padding: 16px 0 20px 0;
`;

export const MainInfoActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CustomerInfoContainer = styled.div`
  padding: 24px 0 20px 0;
`;

export const CustomerInfoActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
