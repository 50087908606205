// Core
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';

// Plugins
import { RequestMethod, requestVantageApi } from '../../../plugins/request';

// Store
import { getUser, setCustomer } from '../../../store/session.store';

// Types
import {
  Customer,
  CustomerStats,
  User,
  UserType,
} from '../../../types/global';

// Media
import mockedCustomerLogo from '../../../media/images/logoSuperior.png';

// Components
import { AdminPanel } from '../../../layout/AdminPanel';
import { CardCustomer } from '../../../components/CardCustomer';
import {
  DashboardIndicator,
  DashboardIndicatorProps,
} from '../../../components/DashboardIndicator';
import { EmptyBox } from '../../../components/EmptyBox';
import { Button, ButtonSize } from '../../../layout/elements/Button';
import { Input } from '../../../layout/elements/Input';
import { Loader, LoaderSize } from '../../../layout/elements/Loader';
import { OptionsDropdownColor } from '../../../layout/elements/OptionsDropdown';
import { PageHeader } from '../../../layout/elements/PageHeader';
import { PaginationFooter } from '../../../layout/elements/PaginationFooter';
import {
  Edit,
  ErrorWarningLine,
  MapPin,
  Profile,
  Search,
  TrashCan,
  View,
  Wifi,
} from '../../../layout/icons';

// Drawers
import { CreateCustomer } from './Drawers/Create';
import { DeleteCustomer } from './Drawers/Delete';
import { ReadCustomer } from './Drawers/Read';
import { UpdateCustomer } from './Drawers/Update';

// View
import { DrawerOperation } from './CustomersIndex.types';
import {
  ButtonContainer,
  CardCustomerContainer,
  Container,
  FilterCustomersContainer,
  IndicatorsPanel,
  InputContainer,
  Layout,
  LoadingContainer,
  NewCustomerContainer,
  PageHeaderContainer,
} from './CustomersIndex.style';

function CustomersIndex(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  // Getters
  const user: User | null = getUser();

  // Redirects if the user is not an admin
  if (!user || user.type !== UserType.admin) {
    navigate('/plant-management');
  }

  /* ***********************************************************************************************
  ******************************************* TYPES ************************************************
  *********************************************************************************************** */

  interface CustomerState {
    currentPage: number;
    customer: Customer | null;
    customers: Customer[];
    drawerOperation: DrawerOperation;
    globalStats: CustomerStats;
    isDrawerOpen: boolean;
    isLoading: boolean;
    pageSize: number | null;
    searchValue?: string | number | null;
  }

  /* ***********************************************************************************************
  ***************************************** LOCAL STATES *******************************************
  *********************************************************************************************** */

  const initialState: CustomerState = {
    currentPage: 1,
    customers: [],
    drawerOperation: DrawerOperation.read,
    globalStats: {
      alarms: null,
      equipmentUnits: null,
      sites: null,
      users: null,
    },
    isDrawerOpen: false,
    isLoading: false,
    pageSize: null,
    searchValue: null,
    customer: null,
  };

  const [state, setState] = useState<CustomerState>(initialState);

  /* ***********************************************************************************************
  ******************************************* METHODS **********************************************
  *********************************************************************************************** */

  /* ********** Request **********  */

  const fetchCustomers = useCallback(async (): Promise<void> => {
    setState((prevState: CustomerState): CustomerState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const customersResponse: Customer[] = await requestVantageApi.customer({
        method: RequestMethod.GET,
        path: '/customer',
      });

      setState((prevState: CustomerState): CustomerState => ({
        ...prevState,
        customers: customersResponse,
        isLoading: false,
      }));
    } catch (error: unknown) {
      setState((prevState: CustomerState): CustomerState => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }, []);

  const fetchGlobalStats = useCallback(async (): Promise<void> => {
    const globalStatsResponse: CustomerStats = await requestVantageApi.customer({
      method: RequestMethod.GET,
      path: '/customer/stats',
      auth: true,
      handleErrors: true,
    });

    setState((prevState: CustomerState): CustomerState => ({
      ...prevState,
      globalStats: globalStatsResponse,
    }));
  }, []);

  /* ********** DRAWERS ********** */

  const openDrawer = useCallback((
    drawerOperation: DrawerOperation,
    customerId?: string,
  ): void => {
    setState((prevState: CustomerState): CustomerState => ({
      ...prevState,
      drawerOperation,
      isDrawerOpen: true,
      customer: customerId
        ? prevState.customers.find((customer: Customer) => customer.id === customerId) || null
        : null,
    }));
  }, []);

  /* Close customer drawer */
  const closeDrawer = (): void => {
    setState({
      ...state,
      isDrawerOpen: false,
      customer: null,
    });
  };

  const updateData = useCallback(async (): Promise<void> => {
    await fetchCustomers();
  }, [fetchCustomers]);

  /* ********** SEARCH ********** */

  /* Filters the list of customers based on the search value */
  const filterCustomers = (): Customer[] => {
    const { customers, searchValue } = state;

    return customers.filter(
      (customer: Customer): boolean => (
        searchValue
          ? customer.name.toLowerCase().includes(String(searchValue).toLowerCase())
          : true
      ),
    );
  };

  /* ********** CARD CUSTOMER ********** */

  /* Set the customerId in the store and navigate to the plant management page */
  const handleCardClick = async (customer: Customer): Promise<void> => {
    setCustomer(customer);
    navigate('/plant-management');
  };

  /* Get the paginated customers */
  const paginatedCustomers: Customer[] = filterCustomers().slice(
    state.pageSize ? (state.currentPage - 1) * state.pageSize : 0,
    state.pageSize ? state.currentPage * state.pageSize : filterCustomers().length,
  );

  /* ********** PAGINATION FOOTER ********** */

  /* Change the page */
  const onPageChange = useCallback((page: number, newPageSize: number): void => {
    setState((prevState: CustomerState): CustomerState => ({
      ...prevState,
      currentPage: page,
      pageSize: newPageSize,
    }));
  }, []);

  /* Check if there are customers */
  const hasCustomers: boolean = paginatedCustomers.length > 0;

  /* ********** DASHBOARD INDICATOR ********** */

  const indicatorsData: DashboardIndicatorProps[] = [
    {
      title: t('view.customers.globalStats.alarms.title'),
      icon: <ErrorWarningLine />,
      value: state.globalStats.alarms,
      critical: state.globalStats.alarms !== null && state.globalStats.alarms > 0,
      tooltipText: t('view.customers.globalStats.alarms.description'),
    },
    {
      title: t('view.customers.globalStats.equipmentUnits.title'),
      value: state.globalStats.equipmentUnits,
      icon: <Wifi />,
      tooltipText: t('view.customers.globalStats.equipmentUnits.description'),
    },
    {
      title: t('view.customers.globalStats.sites.title'),
      value: state.globalStats.sites,
      icon: <MapPin />,
      tooltipText: t('view.customers.globalStats.sites.description'),
    },
    {
      title: t('view.customers.globalStats.activeUsers.title'),
      value: state.globalStats.users,
      icon: <Profile />,
      tooltipText: t('view.customers.globalStats.activeUsers.description'),
    },
  ];

  /* ***********************************************************************************************
  ************************************* COMPONENT HANDLING *****************************************
  *********************************************************************************************** */

  /* Maps each drawer operation to its respective component to be mounted */
  const mountCustomerDrawers: { [key in DrawerOperation]: JSX.Element | null } = {
    create: (
      <CreateCustomer
        isDrawerOpen={state.isDrawerOpen}
        onCloseDrawer={closeDrawer}
        onUpdateData={updateData}
      />
    ),
    read:
      state.customer
        ? (
          <ReadCustomer
            onCustomerAction={(
              operation: DrawerOperation,
              id: string | undefined,
            ) => openDrawer(operation, id)}
            customer={state.customer}
            onCloseDrawer={closeDrawer}
            isDrawerOpen={state.isDrawerOpen}
          />
        ) : null,
    update:
      state.customer
        ? (
          <UpdateCustomer
            customer={state.customer}
            isDrawerOpen={state.isDrawerOpen}
            onCloseDrawer={closeDrawer}
            onUpdateData={updateData}
          />
        )
        : null,
    delete:
      state.customer
        ? (
          <DeleteCustomer
            isDrawerOpen={state.isDrawerOpen}
            onCloseDrawer={closeDrawer}
            customer={state.customer}
            onUpdateData={updateData}
          />
        )
        : null,
  };

  /** ******* GET CUSTOMERS ******** */
  useEffect((): void => {
    fetchCustomers();
  }, [fetchCustomers]);

  /** ******* GET GLOBAL STATS ******** */
  useEffect((): void => {
    fetchGlobalStats();
  }, [fetchGlobalStats]);

  return (
    <AdminPanel>
      <Layout>
        {state.isLoading ? (
          <LoadingContainer>
            <Loader
              size={LoaderSize.xl}
              text={`${t('view.customers.loading')}`}
            />
          </LoadingContainer>
        ) : (
          <Container>
            <PageHeaderContainer>
              <PageHeader
                title={`${t('common.customers')}`}
                description={`${t('view.customers.description')}`}
              />
            </PageHeaderContainer>

            <IndicatorsPanel>
              {indicatorsData.map((item: DashboardIndicatorProps) => (
                <DashboardIndicator
                  testId="dashboard-indicator"
                  key={item.title}
                  title={item.title}
                  value={item.value}
                  tooltipText={item.tooltipText}
                  critical={item.critical}
                  icon={item.icon}
                />
              ))}
            </IndicatorsPanel>

            <InputContainer>
              <NewCustomerContainer>
                <FilterCustomersContainer>
                  <Input
                    testId="filter-customers"
                    placeholder={`${t('view.customers.searchCustomer')}`}
                    value={state.searchValue}
                    label={`${t('view.customers.searchCustomer')}`}
                    prefix={<Search />}
                    onChange={(value?: string | number | null) => {
                      setState((prevState: CustomerState) => ({
                        ...prevState,
                        searchCustomersValue: value,
                        currentPage: 1,
                      }));
                    }}
                  />
                </FilterCustomersContainer>

                <ButtonContainer>
                  <Button
                    testId="new-customer"
                    block={false}
                    size={ButtonSize.xs}
                    onClick={() => openDrawer(DrawerOperation.create)}
                  >
                    {t('view.customers.newCustomer')}
                  </Button>
                </ButtonContainer>
              </NewCustomerContainer>
            </InputContainer>

            {hasCustomers ? (
              <CardCustomerContainer>
                {paginatedCustomers.map((customer: Customer) => (
                  <CardCustomer
                    idCustomer={customer.id}
                    imgSrc={mockedCustomerLogo}
                    key={customer.id}
                    name={customer.name}
                    onClick={() => handleCardClick(customer)}
                    testId={`customer-card-${customer.id}`}
                    options={[
                      {
                        text: `${t('common.view')}`,
                        icon: <View />,
                        color: OptionsDropdownColor.normal,
                        disabled: false,
                        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                          e.stopPropagation();
                          openDrawer(DrawerOperation.read, customer.id);
                        },
                      },
                      {
                        text: `${t('common.edit')}`,
                        icon: <Edit />,
                        color: OptionsDropdownColor.normal,
                        disabled: false,
                        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                          e.stopPropagation();
                          openDrawer(DrawerOperation.update, customer.id);
                        },
                      },
                    ]}
                    secondaryOptions={[
                      {
                        text: `${t('common.delete')}`,
                        icon: <TrashCan />,
                        color: OptionsDropdownColor.danger,
                        disabled: false,
                        onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
                          e.stopPropagation();
                          openDrawer(DrawerOperation.delete, customer.id);
                        },
                      },
                    ]}
                  />
                ))}
              </CardCustomerContainer>
            ) : (
              <EmptyBox
                title={`${t('view.customers.emptyBox.title')}`}
                description={`${t('view.customers.emptyBox.description')}`}
              />
            )}
          </Container>
        )}
        {!state.isLoading && hasCustomers && (
          <PaginationFooter
            totalItems={filterCustomers().length}
            onPageChange={onPageChange}
            currentPage={state.currentPage}
          />
        )}
        {mountCustomerDrawers[state.drawerOperation]}
      </Layout>
    </AdminPanel>
  );
}

export { CustomersIndex };
