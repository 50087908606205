// Core
import styled from 'styled-components';

export const Layout = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.mainNavigation.borderBottomColor};
  padding: 0 44px;
  background-color: ${({ theme }) => theme.color.mainNavigation.mainNavigationNavBgColor};
  
  .ant-menu {
    line-height: 64px;
    border-bottom: none;
    background-color: transparent;
    width: 100%;
    display: flex;
  };
  
  .ant-menu-title-content {
    font-family: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.color.mainNavigation.mainNavigationTextColor};
    font-size: 14px;
    font-style: normal;
  };
    
  .ant-menu-item-selected .ant-menu-title-content {
    color: ${({ theme }) => theme.color.mainNavigation.selectedItemTextColor};
  };

  .ant-menu-item:hover > .ant-menu-title-content {
    color: ${({ theme }) => theme.color.mainNavigation.selectedItemTextColor};
  };
  
  .ant-menu-horizontal > .ant-menu-item:hover::after {
    border-bottom-width: 0;
    border-bottom-color: ${({ theme }) => theme.color.mainNavigation.menuBorderBottomColor};
  };
  
  .ant-menu-horizontal > .ant-menu-item::after {
    inset-inline: 0;
  };

  .ant-menu-horizontal > .ant-menu-submenu-selected::after {
    border-bottom-width: 1px;
    inset-inline: 2px;
  };
  
  .ant-menu-overflow-item {
    padding: 0 24px 4px 20px;
  };
  
  .ant-menu-item-title {
    white-space: nowrap;
  };
  
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border-bottom: 1px solid ${({ theme }) => theme.color.mainNavigation.menuBorderBottomColor} !important;
  };

  .ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
    border-bottom: 1px solid ${({ theme }) => theme.color.mainNavigation.menuBorderBottomColor};
  };
  
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
    border-bottom: 1px solid transparent;
  };
`;

export const AvatarContainer = styled.div`
  color: ${({ theme }) => theme.color.mainNavigation.mainNavigationIconsColor};
  display: flex;
  margin-right: 2px;  
  
  svg {
    width: 22px !important;
    height: 22px !important;
  };
`;

export const CaretDownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OptionsDropdownContainer = styled.div`
  color: ${({ theme }) => theme.color.mainNavigation.caretDownColor};
  align-items: center;
  display: flex;
    
  > div > div {
    display: flex;
    flex-direction: row;
  };

  .ant-dropdown-open {
    > div:nth-child(2) {
      transform: rotate(-180deg);
      transition: transform 0.3s ease-in-out;
    };
  };

  .ant-dropdown-trigger {
    > div:nth-child(2) {
      transition: transform 0.3s ease-in-out;
    };
  };
    
  svg {
    width: 20px;
    height: 20px;
  };
`;

export const UserActions = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
  padding-left: 32px;
`;

export const SearchContainer = styled.div`
  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftSection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 34px;
`;

// @TODO - Uncomment this block when the icons are ready
// export const BellIcon = styled.div`
//   color: ${({ theme }) => theme.color.mainNavigation.mainNavigationIconsColor};
//   display: flex;
//   > svg {
//     width: 20px;
//     height: 20px;
//   };
// `;
