// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Types
import { EdgeDeviceOperationalStatus } from '../../../../../types/global';
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';

// Components
import {
  LineChart,
  LineChartType,
  Series,
  SeriesColorEnum,
} from '../../../../../layout/elements/LineChart';

// View
import { TrendingsRingBounceProps } from './TrendingsRingBounce.types';

function TrendingsRingBounce({
  className,
  operationalData,
  testId,
}: TrendingsRingBounceProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const DEFAULT_TRANSLATE_LEGEND: string = 'view.crusher.trendings.ringBounceSensor';

  const series: Series[] = [
    {
      name: t('common.cloudConnection'),
      field: 'edgeStatus',
      type: LineChartType.fill,
      date: 'timestamp',
      opposite: true,
    },
    {
      name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.vibrationSensor1`),
      field: 'vibrationSensor1',
      type: LineChartType.line,
      date: 'timestamp',
      color: SeriesColorEnum.lightPink,
      unit: '%',
      bullet: true,
      decimals: 2,
    },
    {
      name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.vibrationSensor2`),
      field: 'vibrationSensor2',
      type: LineChartType.line,
      date: 'timestamp',
      color: SeriesColorEnum.lightGreen,
      unit: '%',
      bullet: true,
      decimals: 2,
    },
    {
      name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.vibrationSensor3`),
      field: 'vibrationSensor3',
      type: LineChartType.line,
      date: 'timestamp',
      color: SeriesColorEnum.blue,
      unit: '%',
      bullet: true,
      decimals: 2,
    },
    // {
    //   name: t(`${DEFAULT_TRANSLATE_LEGEND}.legend.maxVibration`),
    //   field: 'maxVibration',
    //   type: LineChartType.line,
    //   date: 'timestamp',
    //   color: 'orange',
    //   unit: '%',
    // },
  ];

  const convertedEdgeStatus: (
    EquipmentUnitDataCrusher | { edgeStatus: number, timestamp: number }
  )[] = operationalData.map((item: EquipmentUnitDataCrusher | EdgeDeviceOperationalStatus) => (
    'edgeStatus' in item
      ? { ...item, edgeStatus: item.edgeStatus === 'on' ? 1 : 0 }
      : item));

  return (
    <LineChart
      activeBullet
      className={className}
      data={convertedEdgeStatus}
      id="ring-bounce"
      series={series}
      testId={testId}
      timeUnit="second"
    />
  );
}

export { TrendingsRingBounce };
