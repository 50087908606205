// Libraries
import { TimeUnit } from '@amcharts/amcharts5/.internal/core/util/Time';

export enum SeriesColorEnum {
  purple = 'purple',
  yellow = 'yellow',
  lightOrange = 'lightOrange',
  green = 'green',
  orange = 'orange',
  blue = 'blue',
  pink = 'pink',
  lightGreen = 'lightGreen',
  lightPink = 'lightPink',
  lightBlue = 'lightBlue',
}

export type SeriesColor = string | SeriesColorEnum;

export enum LineChartType {
  fill = 'fill',
  line = 'line',
  status = 'status',
}

export type Series = {
  bullet?: boolean;
  color?: SeriesColor;
  date: string;
  decimals?: number;
  field: string;
  name: string;
  opposite?: boolean;
  status?: string[];
  type: LineChartType;
  unit?: string;
};

export interface LineChartProps {
  activeBullet?: boolean;
  className?: string;
  data: Record<string, any>[];
  grid?: boolean;
  id: string;
  series: Series[];
  testId?: string;
  timeUnit: TimeUnit;
}
