// Core
import React from 'react';

// Libraries
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Media
import mockedCrusherImage from '../../../../media/images/equipment/crusher.png';

// Types
import { EquipmentCategory, EquipmentModelParameter } from '../../../../types/global';
import { Theme } from '../../../../types/theme';

// Components
import { Col } from '../../../../layout/elements/Col';
import { Drawer } from '../../../../layout/elements/Drawer';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../layout/elements/Paragraph';
import { Row } from '../../../../layout/elements/Row';
import { Edit, TrashCan } from '../../../../layout/icons';

// Plugins
import { DASHBOARD_NULLISH_TEXT, formatNumber } from '../../../../plugins/general';

// View
import { EquipmentModelReadProps } from './EquipmentModelRead.types';
import { MainInfoContainer, SectionTitleContainer } from './EquipmentModelRead.style';

function EquipmentModelRead({
  className,
  equipmentModel,
  isDrawerOpen,
  onCloseDrawer,
  onOpenDelete,
  onOpenEdit,
  testId,
}: EquipmentModelReadProps): JSX.Element | null {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const { equipmentCategory } = useParams<{ equipmentCategory: EquipmentCategory }>();

  /* *********************************************************************************************
  ************************************** COMPONENT HANDLING **************************************
  * ******************************************************************************************* */

  // Helper function to format parameter value
  const formatParameterValue = (value: string | number): string => (
    typeof value === 'number'
      ? formatNumber(value)
      : value
  );

  // Render parameter rows
  const renderParameters = (
    parameters: EquipmentModelParameter[],
  ): JSX.Element[] => parameters.map((parameter: EquipmentModelParameter) => (
    <Col cols={12} key={parameter.name}>
      <Paragraph
        size={ParagraphSize.sm}
        weight={ParagraphWeight.bold}
        color={theme.color.equipmentModelsView.drawerParamTextColor}
      >
        {t(`equipment.${equipmentCategory}.parameters.${parameter.name}`)}
      </Paragraph>
      <Paragraph weight={ParagraphWeight.medium}>
        {
          !_.isNil(parameter.value)
            ? formatParameterValue(parameter.value)
            : DASHBOARD_NULLISH_TEXT
        }
      </Paragraph>
    </Col>
  ));

  return (
    !equipmentModel || !equipmentCategory
      ? null
      : (
        <Drawer
          testId={testId}
          className={className}
          title={t(`common.equipmentCategory.${equipmentCategory}.singular`)}
          open={isDrawerOpen}
          onClose={onCloseDrawer}
          footer={false}
          preview={{
            previewImage: mockedCrusherImage, // @TODO fix when backend accepts images
            primaryPreviewAction: {
              onClick: onOpenDelete,
              children: <TrashCan />,
            },
            secondaryPreviewAction: {
              onClick: onOpenEdit,
              children: <Edit />,
            },
          }}
        >
          <MainInfoContainer>
            <Row>
              <Col>
                <SectionTitleContainer>
                  <Paragraph weight={ParagraphWeight.bold}>
                    {t('common.mainInfo')}
                  </Paragraph>
                </SectionTitleContainer>
              </Col>
            </Row>
            <Row>
              <Col cols={12}>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.bold}
                  color={theme.color.equipmentModelsView.drawerParamTextColor}
                >
                  {t('common.commercialName')}
                </Paragraph>
                <Paragraph weight={ParagraphWeight.medium}>
                  {equipmentModel.commercialName || DASHBOARD_NULLISH_TEXT}
                </Paragraph>
              </Col>
              <Col cols={12}>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.bold}
                  color={theme.color.equipmentModelsView.drawerParamTextColor}
                >
                  {t('common.superiorPartNumber')}
                </Paragraph>
                <Paragraph weight={ParagraphWeight.medium}>
                  {equipmentModel.superiorPartNumber || DASHBOARD_NULLISH_TEXT}
                </Paragraph>
              </Col>
            </Row>
          </MainInfoContainer>
          <Row>
            <SectionTitleContainer>
              <Paragraph weight={ParagraphWeight.bold}>
                {t('common.parameters')}
              </Paragraph>
            </SectionTitleContainer>
          </Row>
          <Row>{renderParameters(equipmentModel.parameters)}</Row>
        </Drawer>
      )
  );
}

export { EquipmentModelRead };
