/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/
// Core
import styled from 'styled-components';

// Libraries
import { Dropdown } from 'antd';

// Theme
import { Theme } from '../../../types/theme';

// Component
import { Options, OptionsDropdownColor } from './OptionsDropdown.types';

export const mapBackgroundByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.dangerBackgroundHoverColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.optionItemBgHoverColor,
});

export const mapBackgroundDisabledByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.disabledBackgroundColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.disabledBackgroundColor,
});

export const mapTextByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.dangerTextColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.optionItemTextColor,
});

export const mapTextDisabledByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.disabledDangerTextColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.disabledOptionTextColor,
});

export const mapTextDisabledHoverByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.disabledDangerTextHoverColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.disabledOptionTextHoverColor,
});

export const mapTextHoverByColor = (theme: Theme): { danger: string, normal: string } => ({
  [OptionsDropdownColor.danger]: theme.color.optionsDropdown.dangerTextHoverColor,
  [OptionsDropdownColor.normal]: theme.color.optionsDropdown.optionItemTextHoverColor,
});

export const ListContainer = styled.div`
  padding: 0;
  margin: 0;
  border-radius: 10px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.color.optionsDropdown.optionsContainerBgColor};
  border: 1px solid ${({ theme }) => theme.color.optionsDropdown.optionsContainerBorder};
  box-shadow: 0 7px 14px 0 ${({ theme }) => theme.color.optionsDropdown.optionsContainerBoxShadow};
  transition: all 0.3s ease-in-out;
  width: 100%;
`;

export const Items = styled.div<Pick<Options, 'color' | 'disabled'>>`
  height: 42px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 10px 16px;
  width: 210px;
  font-family: ${({ theme }) => theme.font.regular};
  color: ${({
    theme,
    color,
    disabled,
  }) => (disabled ? mapTextDisabledByColor(theme)[color] : mapTextByColor(theme)[color])};

  > svg {
    width: 16px;
    height: 16px;
  };

  :hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    background-color: ${({
      theme,
      color,
      disabled,
    }) => (disabled ? mapBackgroundDisabledByColor(theme) : mapBackgroundByColor(theme)[color])};
    color: ${({
      theme,
      color,
      disabled,
    }) => (
      disabled
        ? mapTextDisabledHoverByColor(theme)[color]
        : mapTextHoverByColor(theme)[color]
    )};
  };
`;

export const MenuItemsContainer = styled.div`
  margin: 4px;
  gap: 16px;
  width: 100%;
  padding: 0;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.optionsDropdown.dividerColor};
`;

export const Container = styled(Dropdown)`
  :hover {
    cursor: pointer;
  };
    
  > svg {
    width: 20px;
    height: 20px;
  };
`;
