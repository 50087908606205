/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/
// Core
import styled from 'styled-components';

// Libraries
import { Input as InputAntD } from 'antd';

// Component
import { InputProps } from './Input.types';

// Types
import { Theme } from '../../../types/theme';

// Determine label color
const getLabelColor = ({
  disabled,
  error,
  focused,
  theme,
  filled,
}: {
  disabled?: boolean,
  error?: boolean,
  focused: boolean,
  theme: Theme,
  filled: boolean,
}): string => {
  let color: string = theme.color.input.defaultLabelTextColor;

  if (disabled) {
    color = theme.color.input.disabledLabelTextColor;
  } else if (error && focused) {
    color = theme.color.input.errorLabelTextColor;
  } else if (error && !filled) {
    color = 'auto';
  } else if (error) {
    color = theme.color.input.errorLabelTextColor;
  } else if (focused) {
    color = theme.color.input.focusedLabelTextColor;
  } else if (filled) {
    color = theme.color.input.defaultLabelTextColor;
  }

  return color;
};

// Determine border color
const getBorderColor = ({
  disabled,
  error,
  hover,
  theme,
}: {
  disabled?: boolean,
  error?: boolean,
  hover?: boolean,
  theme: Theme,
}) => {
  let borderColor;

  if (disabled) {
    borderColor = theme.color.input.defaultBorderColor;
  } else if (error) {
    borderColor = theme.color.input.errorInputBorderColor;
  } else if (hover) {
    borderColor = 'green';
  } else {
    borderColor = theme.color.input.defaultBorderColor;
  }

  return borderColor;
};

// Determine prefix and suffix color
const getPrefixAndSuffixColor = ({
  disabled,
  error,
  theme,
}: {
  disabled?: boolean,
  error?: string,
  theme: Theme,
}) => {
  let prefixColor;
  let suffixColor;

  if (disabled) {
    prefixColor = theme.color.input.prefixAndSuffixDisabledColor;
    suffixColor = theme.color.input.prefixAndSuffixDisabledColor;
  } else if (error === 'true') {
    prefixColor = theme.color.input.prefixAndSuffixErrorColor;
    suffixColor = theme.color.input.prefixAndSuffixErrorColor;
  } else {
    prefixColor = theme.color.input.prefixColor;
    suffixColor = theme.color.input.suffixColor;
  }

  return { prefixColor, suffixColor };
};

/*
  Props explanation for InputProps used in ElementTypePassword:
  label: Indicates if the input has a label
  prefix: Element to be displayed as a prefix in the input
  error: Indicates if there is an error. If true, it changes the color of the prefix and suffix
*/
export const ElementTypePassword = styled(InputAntD.Password)<
  Pick<InputProps, 'label' | 'prefix'> & { error: string }
>`
  padding: ${({ prefix, suffix }) => (
    prefix || suffix
      ? '16px 16px 16px 15px'
      : '18px 15px 6px 15px'
  )};
  height: 50px;
  transition: color 0.3s;
  background: ${({ disabled, theme }) => (
    disabled
      ? theme.color.input.disabledBackgroundColor
      : 'auto'
  )};

  /* ************** PREFIX ******************* */
  .ant-input-prefix,
  .ant-input-prefix div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s;
    color: ${({
      theme,
      error,
      disabled,
    }) => getPrefixAndSuffixColor({
      theme,
      error,
      disabled,
    }).prefixColor};
  };

  /* ************** SUFFIX ******************* */
  .ant-input-suffix,
  .ant-input-suffix div{
    display: flex;
    align-items: flex-start;
    cursor: not-allowed;
    transition: color 0.3s;
    color: ${({
      theme,
      error,
      disabled,
    }) => getPrefixAndSuffixColor({
      theme,
      error,
      disabled,
    }).suffixColor};
  };
  
  .anticon.ant-input-password-icon {
    &:hover {
      color: ${({ disabled, theme }) => (
        disabled
          ? theme.color.input.suffixColor
          : 'auto'
      )};
    };
    
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  };

  /* ************** INPUT VALUE CONTAINER ******************* */
  input {
    color: ${({ theme }) => (theme.color.input.defaultValueTextColor)};
    height: 20px;
    font-size: 16px !important;
    font-family: ${({ theme }) => theme.font.regular};
    transition: margin-top 0.3s, margin-left 0.3s, color 0.3s !important;
    margin-left: ${({ prefix }) => (prefix ? '5px' : '0')};
    margin-top: ${({
      prefix,
      suffix,
      label,
    }) => ((prefix || suffix) && label ? '3px' : '0')};
    background: ${({ disabled, theme }) => (
      disabled
        ? theme.color.input.disabledBackgroundColor
        : 'auto'
    )};
  };

  &&.ant-input {
    font-size: 16px !important;
    font-family: ${({ theme }) => theme.font.regular};
  };
`;

/*
  Props explanation for InputProps used in Label:
  error: Boolean indicating if there is an error
  value: Determines if the input has a value
  focused: Indicates if the input is focused
  prefixWidth: The width of the prefix element in the input
  disabled: Indicates if the input is disabled
*/
export const Label = styled.label<Pick<InputProps, | 'error' | 'disabled'> & {
  focused: boolean,
  prefixWidth: number,
  filled: boolean,
}>`
  position: absolute;
  letter-spacing: 0;
  text-align: left;
  user-select: none;
  color: ${({
    theme,
    error,
    focused,
    disabled,
    filled,
  }) => getLabelColor({
    theme,
    error,
    focused,
    disabled,
    filled,
  })};
  pointer-events: none;
  transition: top 0.3s, font-size 0.2s, color 0.3s !important;
  z-index: 1;
  top: ${({ filled, focused }) => (filled || focused ? '6px' : '15px')};
  left: ${({ prefixWidth }) => `${(prefixWidth) ? prefixWidth + 25 : 16}px`};
  font-size: ${({ filled, focused }) => (filled || focused ? '12px' : '16px')};
  font-family: ${({
    theme,
    filled,
    focused,
  }) => (
    (filled || focused)
      ? theme.font.medium
      : theme.font.regular
  )};
`;

const getPadding = ({
  prefix,
  suffix,
  label,
}: {
  prefix: boolean;
  suffix: boolean;
  label: boolean;
}): string => {
  let padding: string;

  if (prefix || suffix) {
    padding = '16px 16px 16px 15px';
  } else {
    padding = label ? '18px 15px 6px 15px' : '6px 15px 6px 15px';
  }

  return padding;
};

/*
  Props explanation for InputProps used in Element:
  label: Indicates if the input has a label
  prefix: Element to be displayed as a prefix in the input
  suffix: Element to be displayed as a suffix in the input
  error: Indicates if there is an error. If true, it changes the color of the prefix and suffix
*/
export const Element = styled(InputAntD)<Pick<InputProps,
'label' | 'prefix' | 'suffix'> & { error: string }>`
  padding: ${({
    prefix,
    suffix,
    label,
  }) => getPadding({ prefix: !!prefix, suffix: !!suffix, label: !!label })};
  height: 50px;
  transition: color 0.3s;
  background: ${({ disabled, theme }) => (
    disabled
      ? theme.color.input.disabledBackgroundColor
      : 'auto'
  )};
  
  /* ************** PREFIX ******************* */
  .ant-input-prefix,
  .ant-input-prefix div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    transition: color 0.3s;
    color: ${({
      theme,
      error,
      disabled,
    }) => getPrefixAndSuffixColor({
      theme,
      error,
      disabled,
    }).prefixColor};
  };

  /* ************** SUFFIX ******************* */
  .ant-input-suffix {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    transition: color 0.3s;
    color: ${({
      theme,
      error,
      disabled,
    }) => getPrefixAndSuffixColor({
      theme,
      error,
      disabled,
    }).suffixColor};
  };

  /* ************** INPUT VALUE CONTAINER ******************* */
  input {
    height: 20px;
    font-size: 16px !important;
    font-family: ${({ theme }) => theme.font.regular};
    transition: margin-top 0.3s, margin-left 0.3s !important;
    margin-left: ${({ prefix }) => ((prefix) ? '5px' : '0')};
    margin-top: ${({
      prefix,
      suffix,
      label,
    }) => ((prefix || suffix) && label ? '5px' : '0')};
    background: ${({ disabled, theme }) => (
      disabled
        ? theme.color.input.disabledBackgroundColor
        : 'auto'
    )};
  };
  
  &&.ant-input {
    font-size: 16px !important;
    font-family: ${({ theme }) => theme.font.regular};
  };

  /* ********** INCREMENT AND DECREMENT BUTTON ********** */
  & input[type="number"]::-webkit-inner-spin-button,
  & input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  };

  /* ********** PLACEHOLDER FONT SIZE WHEN NOT FOCUSED ********** */
  input:not(:focus)::placeholder,
  &:not(:focus)::placeholder {
    font-size: ${({ label }) => `${(label) ? 0 : 'auto'}`};
  };
`;

/*
  Props explanation for InputProps used in Container:
  error: Boolean indicating if there is an error
  disabled: Boolean indicating if the input is disabled
*/
export const Container = styled.div<Pick<InputProps, 'error' | 'disabled'>>`
  position: relative;

  ${Element},
  ${ElementTypePassword} {
    /* ********** BORDER COLOR IN DEFAULT AND ERROR STATES ********** */
    border-radius: 4px !important;
    transition: border-color 0.3s;
    border: 1px solid ${({
      theme,
      error,
      disabled,
    }) => (getBorderColor({
      theme,
      error,
      disabled,
    }))};

    /* ********** BORDER COLOR WHEN HOVERED ********** */
    &:hover {
      transition: border-color 0.3s;
      
      &:not(.ant-input-affix-wrapper-disabled) {
        border: 1px solid ${({ error, theme }) => (
          error
            ? theme.color.input.errorInputBorderColor
            : theme.color.input.hoverBorderColor
        )};
      };
    };

    /* ********** BORDER COLOR WHEN FOCUSED ********** */
    &.ant-input:focus,
    &.ant-input-affix-wrapper-focused {
      box-shadow: none;
      transition: border-color 0.3s;
      border: 1px solid ${({ theme, error }) => (
        error
          ? theme.color.input.errorInputBorderColor
          : theme.color.input.focusedInputBorderColor
      )} !important;
    };
  };

  /* ********** COLOR LABEL WHEN HOVER CONTAINER ********** */
  &:hover ${Label} {
    transition: color 0.3s;
    color: ${({
      error,
      disabled,
      theme,
    }) => (error || disabled ? 'auto' : theme.color.input.hoverLabelTextColor)};
  };
`;

/* ************** SUPPORTING TEXT ******************* */
export const SupportingText = styled.div<Pick<InputProps, 'disabled'>>`
  margin: 8px 14px;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
`;
