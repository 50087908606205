// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import {
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  overallStatusToTagType,
  overallStatusToText,
  runningStatusToTagType,
  runningStatusToText,
  weatherModeStatusToTagType,
  weatherModeStatusToText,
} from '../../../../plugins/crusher';

// Types
import { EquipmentUnitDataCrusher } from '../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../types/global';
import { Theme } from '../../../../types/theme';

// Components
import { Col } from '../../../../layout/elements/Col';
import { ModuleHeader } from '../../../../layout/elements/ModuleHeader';
import { Row } from '../../../../layout/elements/Row';
import { Speedometer } from '../../../../layout/elements/Speedometer';
import { Tag } from '../../../../layout/elements/Tag';
import { Thermometer } from '../../../../layout/elements/Thermometer';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../layout/elements/Paragraph';

// View
import {
  CoolerFanStatusContainer,
  LubePressureStatusContainer,
  LubricationContainer,
  LubricationContent,
  OverallStatusContainer,
  ThermometersContainer,
  WinterModeStatusContainer,
} from './LubricationModule.style';
import { CrusherLubricationModuleProps } from './LubricationModule.types';

function LubricationModule({
  className,
  onOpenDrawer,
  operationalData,
  testId,
}: CrusherLubricationModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  return (
    <LubricationContainer
      data-testid={testId}
      className={className}
    >
      <LubricationContent>
        <Row>
          <Col>
            <ModuleHeader onClick={onOpenDrawer} title={t('view.crusher.lubrication.title')} />
          </Col>
        </Row>
        <Row>
          <Col cols={8}>
            <OverallStatusContainer>
              <Paragraph
                size={ParagraphSize.sm}
                weight={ParagraphWeight.medium}
                color={theme.color.crusherDashboard.statusTitleColor}
              >
                {t('view.crusher.overallStatus')}
              </Paragraph>
              <Tag
                text={overallStatusToText(lastUnitData?.lubricationOverallStatus)}
                type={overallStatusToTagType(
                  lastUnitData?.lubricationOverallStatus,
                  lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                )}
              />
            </OverallStatusContainer>
          </Col>
          <Col cols={8}>
            <WinterModeStatusContainer>
              <Paragraph
                size={ParagraphSize.sm}
                weight={ParagraphWeight.medium}
                color={theme.color.crusherDashboard.statusTitleColor}
              >
                {t('view.crusher.lubrication.winterMode')}
              </Paragraph>
              <Tag
                text={weatherModeStatusToText(lastUnitData?.weatherModeStatus)}
                type={weatherModeStatusToTagType(
                  lastUnitData?.weatherModeStatus,
                  lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                )}
                className="status"
              />
            </WinterModeStatusContainer>
          </Col>
          <Col cols={8}>
            <CoolerFanStatusContainer>
              <Paragraph
                size={ParagraphSize.sm}
                weight={ParagraphWeight.medium}
                color={theme.color.crusherDashboard.statusTitleColor}
              >
                {t('view.crusher.lubrication.coolerFan')}
              </Paragraph>
              <Tag
                text={runningStatusToText(lastUnitData?.coolerFanStatus)}
                type={runningStatusToTagType(
                  lastUnitData?.coolerFanStatus,
                  lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                )}
                className="status"
              />
            </CoolerFanStatusContainer>
          </Col>
        </Row>

        <Row style={{ flex: 1 }}>
          <Col cols={16}>
            <ThermometersContainer>
              <Row>
                <Col cols={8}>
                  <Thermometer
                    className="thermometer"
                    minValue={-20}
                    maxValue={150}
                    value={lastUnitData?.lubricationReturnTemperature}
                    unit="°C"
                    thresholds={{
                      lowAlarm: 18,
                      lowWarning: 20,
                      highWarning: 52,
                      highAlarm: 60,
                    }}
                    title={`${t('view.crusher.lubrication.returnTemp')}`}
                    running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                  />
                </Col>
                <Col cols={8}>
                  <Thermometer
                    className="thermometer"
                    minValue={-20}
                    maxValue={150}
                    value={lastUnitData?.lubricationTankTemperature}
                    unit="°C"
                    thresholds={{
                      lowAlarm: 17,
                      lowWarning: 21,
                      highWarning: 54,
                      highAlarm: 60,
                    }}
                    title={`${t('view.crusher.lubrication.tankTemp')}`}
                    running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                  />
                </Col>
                <Col cols={8}>
                  <Thermometer
                    className="thermometer"
                    minValue={-20}
                    maxValue={150}
                    value={lastUnitData?.lubricationSupplyTemperature}
                    unit="°C"
                    thresholds={{ lowWarning: 20 }}
                    title={`${t('view.crusher.lubrication.supplyTemp')}`}
                    running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                  />
                </Col>
              </Row>
            </ThermometersContainer>
          </Col>

          <Col cols={8}>
            <LubePressureStatusContainer>
              <Speedometer
                className="speedometer"
                minValue={0}
                maxValue={145}
                value={lastUnitData?.lubricationOilPressure}
                unit="psi"
                thresholds={{ lowWarning: 17, lowAlarm: 13 }}
                title={`${t('view.crusher.lubrication.lubePressure')}`}
                running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
              />
            </LubePressureStatusContainer>
          </Col>
        </Row>

      </LubricationContent>
    </LubricationContainer>
  );
}

export { LubricationModule };
