// Core
import React from 'react';

// Middlewares
import Auth from './middlewares/Auth';

// Views
import { AdminActions } from './views/AdminPanel/AdminActions/AdminActions';
import { CrusherDashboard } from './views/Equipment/Crusher';
import { CustomersIndex } from './views/AdminPanel/Customer/CustomersIndex';
import { EquipmentModelTable } from './views/AdminPanel/EquipmentModel/EquipmentModelTable';
import { EquipmentModelsIndex } from './views/AdminPanel/EquipmentModel';
import { Inventory } from './views/AdminPanel/Inventory/Inventory';
import { Login } from './views/Login';
import { PlantNavigation } from './views/PlantNavigation/PlantNavigation';

const routes = [
  {
    path: '/',
    element: <Auth Component={CustomersIndex} Fallback={Login} />,
  },
  {
    path: 'plant-management',
    element: <Auth Component={PlantNavigation} />,
  },
  {
    path: 'admin-actions',
    element: <Auth Component={AdminActions} />,
  },
  {
    path: '/crusher/:id',
    element: <Auth Component={CrusherDashboard} />,
  },
  {
    path: 'equipment-models',
    children: [
      {
        index: true,
        element: <EquipmentModelsIndex />,
      },
      {
        path: ':equipmentCategory',
        element: <EquipmentModelTable />,
      },
    ],
  },
  {
    path: 'inventory',
    element: <Auth Component={Inventory} />,
  },
];

export default routes;
