// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';

// Layout
import { Paragraph, ParagraphSize, ParagraphWeight } from '../Paragraph';
import { CaretRight, Plus } from '../../icons';

// Component
import {
  Element,
  IconContainer,
  IconRightContainer,
  ImgContainer,
  TextContainer,
} from './StepCard.style';
import { StepCardProps } from './StepCard.types';

// Types
import { Theme } from '../../../types/theme';

function StepCard({
  className,
  description,
  icon = <Plus />,
  imgSrc,
  onClick,
  testId,
  title,
}: StepCardProps): JSX.Element {
  // Dependencies
  const theme: Theme = useTheme();

  return (
    <Element
      data-testid={testId}
      className={className}
      onClick={onClick}
    >
      {imgSrc
        ? (
          <ImgContainer>
            <img src={imgSrc} alt={title} />
          </ImgContainer>
        ) : (
          <IconContainer>{icon}</IconContainer>
        )}
      <TextContainer>
        <Paragraph weight={ParagraphWeight.bold} color={theme.color.stepCard.titleColor}>
          {title}
        </Paragraph>
        {description && (
          <Paragraph
            testId="step-card-description"
            size={ParagraphSize.sm}
            color={theme.color.stepCard.descriptionColor}
          >
            {description}
          </Paragraph>
        )}
      </TextContainer>
      <IconRightContainer>
        <CaretRight />
      </IconRightContainer>
    </Element>
  );
}

export { StepCard };
