// Core
import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 44px 44px 44px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 34px;
  flex: 1;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  width: 25%;
`;

export const ButtonContainer = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
`;

export const TableContainer = styled.div`
  padding-bottom: 44px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
