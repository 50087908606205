// Core
import React, {
  useCallback,
  useState,
} from 'react';

// Libraries
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

// Locale
import i18n from '../../../../locale/i18n';

// Types
import { EquipmentCategory } from '../../../../types/global';

// Plugins
import { RequestMethod, requestVantageApi } from '../../../../plugins/request';

// Components
import { DeleteWarningBox } from '../../../../components/DeleteWarningBox';
import { Drawer } from '../../../../layout/elements/Drawer';
import { Input } from '../../../../layout/elements/Input';
import { Paragraph } from '../../../../layout/elements/Paragraph';
import { showToast, ToastType } from '../../../../layout/elements/Toast';

// View
import { EquipmentModelDeleteProps } from './EquipmentModelDelete.types';
import {
  DeleteActionContainer,
  DeleteWarningBoxContainer,
  InputContainer,
} from './EquipmentModelDelete.style';

function EquipmentModelDelete({
  equipmentModel,
  isDrawerOpen,
  onCloseDrawer,
  testId,
  updateEquipmentModels,
}: EquipmentModelDeleteProps): JSX.Element | null {
  // Dependencies
  const { t } = useTranslation();
  const { equipmentCategory } = useParams<{ equipmentCategory: EquipmentCategory }>();

  /* *********************************************************************************************
  **************************************** INITIAL STATE ****************************************
  * ******************************************************************************************* */

  interface State {
    deleteConfirmationValue: string | null;
    enabledToDelete: boolean;
    isDeletionInProgress: true | null | number;
  }

  const initialState: State = {
    deleteConfirmationValue: null,
    enabledToDelete: false,
    isDeletionInProgress: null,
  };

  const [state, setState] = useState<State>(initialState);

  /* *********************************************************************************************
  ******************************************* METHODS *******************************************
  * ******************************************************************************************* */

  const handleClose = useCallback((): void => {
    onCloseDrawer();
    setState((prev) => ({ ...prev, deleteConfirmationValue: null, isDeletionInProgress: null }));
  }, [onCloseDrawer]);

  const startDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isDeletionInProgress: true,
    }));
  };

  const stopDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isDeletionInProgress: 100,
    }));
  };

  const handleDelete = async (): Promise<void> => {
    startDrawerLoading();

    try {
      await requestVantageApi.equipmentModel({
        method: RequestMethod.DELETE,
        path: `/${equipmentCategory}/${equipmentModel?.id}`,
      });
      updateEquipmentModels();
      showToast({
        text: i18n.t(
          'view.equipmentModel.delete.toastEquipmentDeleted',
          { model: i18n.t(`common.equipmentCategory.${equipmentCategory}.singular`) },
        ),
        type: ToastType.success,
      });
    } catch (error: unknown) {
      console.error('Failed to delete equipment model', error);
    }

    stopDrawerLoading();
    setTimeout((): void => {
      handleClose();
    }, 300);
  };

  const handleDeleteConfirmationValue = (value: string | null): void => {
    setState((prev: State) => ({
      ...prev,
      deleteConfirmationValue: typeof value === 'string' ? value.toUpperCase() : null,
      enabledToDelete: value?.toUpperCase() === i18n.t('common.delete').toUpperCase(),
    }));
  };

  /* **********************************************************************************************
  ************************************** COMPONENT HANDLING ***************************************
  * ******************************************************************************************** */

  return (
    !equipmentModel
      ? null
      : (
        <Drawer
          testId={testId}
          title={t(
            'view.equipmentModel.delete.drawerTitle',
            { model: t(`common.equipmentCategory.${equipmentCategory}.singular`) },
          )}
          onClose={handleClose}
          open={isDrawerOpen}
          primaryAction={{
            children: `${t('common.delete')}`,
            onClick: handleDelete,
            disabled: !state.enabledToDelete,
            loading: state.isDeletionInProgress,
            loadingText: `${t('common.deleting')}`,
          }}
          secondaryAction={{ children: `${t('common.cancel')}`, onClick: handleClose }}
        >
          <DeleteWarningBoxContainer>
            <DeleteWarningBox
              testId="delete-warning-box-delete-drawer"
              itemIdentifier={{
                key: `${t('common.superiorPartNumber')}`,
                value: equipmentModel.superiorPartNumber,
              }}
              itemType={`${t('common.model')}`}
            />
          </DeleteWarningBoxContainer>
          <DeleteActionContainer>
            <Paragraph>
              <Trans
                i18nKey="view.equipmentModel.delete.deleteSection"
                values={{ delete: `${t('common.delete')}`.toUpperCase() }}
                components={{ strong: <strong /> }}
              />
            </Paragraph>
            <InputContainer>
              <Input
                testId="delete-confirmation-input"
                placeholder={`${t('view.equipmentModel.delete.deletePlaceholder')}`}
                onChange={
                  (value: string | number | null | undefined) => handleDeleteConfirmationValue(
                    value as string,
                  )
                }
                label={`${t('view.equipmentModel.delete.deletePlaceholder')}`}
                value={state.deleteConfirmationValue}
                maxLength={35}
              />
            </InputContainer>
          </DeleteActionContainer>
        </Drawer>
      )
  );
}

export { EquipmentModelDelete };
