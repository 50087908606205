// Core
import styled from 'styled-components';

// Plugins
import { hexToRgba } from '../../plugins/general';

export const CardEquipmentComponent = styled.div`
  width: 305px;
  height: 238px;
  box-sizing: border-box;
  padding: 16px 20px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.cardEquipment.borderColor};
  background: ${({ theme }) => theme.color.cardEquipment.backgroundColor};
  cursor: pointer;
  transition: all 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  
  /* ********** HOVER ********** */
  &:hover {
    box-shadow: 2px 2px 6px ${({ theme }) => hexToRgba(theme.color.cardEquipment.hoverShadowColor, 0.5)};
    border: 1px solid ${({ theme }) => theme.color.cardEquipment.hoverBorderColor};
  };
  
  &:active {
    transform: scale(0.99);
  };
`;

export const ImageEquipment = styled.img`
  width: 96px;
  height: 96px;
  border-radius: 4px;
  object-fit: contain;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.color.cardEquipment.imageBackgroundColor};
`;

export const EquipmentIndicators = styled.div`
  padding: 16px 0 0 0;
`;

export const NameEquipmentContainer = styled.div`
  max-width: 263px;
`;

export const ContentContainer = styled.div`
  width: max-content;
  max-width: 100%;
`;

export const ContentRegisterModels = styled.div`
  padding-bottom: 8px;
`;
