// Core
import styled from 'styled-components';

export const Element = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px;
  cursor: pointer;
  word-break: break-word;
  border-radius: 4px;
  /* ******************* TRANSITION ******************* */
  transition: box-shadow 0.25s ease-in-out;
  /* ******************* BORDER COLOR ******************* */
  border: 1px solid ${({ theme }) => theme.color.stepCard.borderColor};

  /* ******************* HOVER ******************* */
  &:hover {
    box-shadow: 0 3px 10px ${({ theme }) => theme.color.stepCard.hoverBoxShadowColor};
  };

  /* ******************* ACTIVE ******************* */
  &:active {
    box-shadow: 0 2px 8px ${({ theme }) => theme.color.stepCard.clickBoxShadowColor};
  };
`;

export const IconContainer = styled.div`
  min-width: 34px;
  min-height: 34px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  /* ******************* BACKGROUND COLOR ******************* */
  background-color: ${({ theme }) => theme.color.stepCard.iconBackgroundColor};

  /* ******************* SVG ******************* */
  > svg {
    width: 20px;
    height: 20px;
    /* ******************* ICON COLOR ******************* */
    color: ${({ theme }) => theme.color.stepCard.iconColor};
    /* ******************* FILL COLOR ******************* */
    fill: ${({ theme }) => theme.color.stepCard.iconFill};
    z-index: 1;
  };
`;

export const ImgContainer = styled.div`
  min-width: 34px;
  min-height: 34px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* ******************* IMG BACKGROUND COLOR ******************* */
  background-color: ${({ theme }) => theme.color.stepCard.imageBackgroundColor};

  /* ******************* IMG ******************* */
  > img {
    object-fit: contain;
    width: 48px;
    height: 48px;
  };
`;

export const TextContainer = styled.div`
  margin: 0 4px 0 24px;
  flex: min-content;
  display: flex;
  flex-direction: column;
`;

export const IconRightContainer = styled.div`
  /* ******************* SVG ******************* */
  > svg {
    min-width: 24px;
    min-height: 24px;
    color: ${({ theme }) => theme.color.stepCard.rightIconColor};
    fill: ${({ theme }) => theme.color.stepCard.rightIconFill};
  };
`;
