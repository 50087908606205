// Core
import React, {
  useEffect,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import { capitalizeFirstLetter } from '../../../../../plugins/general';
import { RequestMethod, requestVantageApi } from '../../../../../plugins/request';

// Components
import { CircleInfo } from '../../../../../layout/icons';
import { Drawer } from '../../../../../layout/elements/Drawer';
import { Input } from '../../../../../layout/elements/Input';
import { Paragraph, ParagraphWeight } from '../../../../../layout/elements/Paragraph';
import { showToast, ToastType } from '../../../../../layout/elements/Toast';
import { Tooltip, TooltipPlacement } from '../../../../../layout/elements/Tooltip';

// View
import { UpdateInventoryProps } from './Update.types';
import {
  CustomerInfoActionsContainer,
  CustomerInfoContainer,
  MainInfoActionsContainer,
  MainInfoContainer,
} from './Update.style';

function UpdateInventory({
  className,
  customer,
  equipmentUnit,
  isDrawerOpen,
  onCloseDrawer,
  onUpdateData,
  testId,
}: UpdateInventoryProps): JSX.Element {
  const { t } = useTranslation();

  /* ***********************************************************************************************
  *************************************** LOCAL STATES *********************************************
  *********************************************************************************************** */

  interface State {
    valueSerialNumber?: string | number | null;
    valueCustomerPartNumber?: string | number | null;
    isLoading: number | true | null
  }

  const initialState: State = {
    valueSerialNumber: equipmentUnit?.serialNumber,
    valueCustomerPartNumber: equipmentUnit?.customerPartNumber,
    isLoading: null,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  *********************************************** METHODS ******************************************
  *********************************************************************************************** */

  const startDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: true,
    }));
  };

  const stopDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: 100,
    }));
  };

  /* Request */
  const onUpdate = async (): Promise<void> => {
    startDrawerLoading();

    try {
      await requestVantageApi.equipmentUnit({
        method: RequestMethod.PUT,
        path: `/crusher/inventory/${equipmentUnit?.id}`,
        params: { idCustomer: customer?.id },
        body: {
          serialNumber: state.valueSerialNumber,
          customerPartNumber: state.valueCustomerPartNumber,
        },
      });

      if (equipmentUnit && equipmentUnit.equipmentCategory) {
        showToast({
          text: t('view.inventory.update.updateEquipment', {
            category: capitalizeFirstLetter(equipmentUnit.equipmentCategory),
          }),
          type: ToastType.success,
        });
      }
      setState((prevState: State): State => ({
        ...prevState,
        isLoading: null,
      }));

      stopDrawerLoading();

      setTimeout((): void => {
        onCloseDrawer();
        onUpdateData();
      }, 300);
    } catch (error: unknown) {
      setState((prevState: State): State => ({
        ...prevState,
        isLoading: null,
      }));
    }
  };

  const isUpdateButtonDisabled = (): boolean => (
    !state.valueSerialNumber || !state.valueCustomerPartNumber
  );

  /* ***********************************************************************************************
  ************************************ COMPONENT HANDLING ******************************************
  *********************************************************************************************** */

  useEffect((): void => {
    setState({
      valueSerialNumber: equipmentUnit?.serialNumber,
      valueCustomerPartNumber: equipmentUnit?.customerPartNumber,
      isLoading: null,
    });
  }, [equipmentUnit]);

  return (
    <Drawer
      testId={testId}
      className={className}
      title={`${t('view.inventory.update.title')}`}
      onClose={onCloseDrawer}
      open={isDrawerOpen}
      primaryAction={{
        children: `${t('common.edit')}`,
        onClick: onUpdate,
        disabled: isUpdateButtonDisabled(),
        loading: state.isLoading,
        loadingText: `${t('common.editing')}`,
      }}
      secondaryAction={{ children: `${t('common.cancel')}`, onClick: onCloseDrawer }}
    >
      <MainInfoContainer>
        <Paragraph weight={ParagraphWeight.bold}>
          {`${t('common.mainInfo')}`}
        </Paragraph>
      </MainInfoContainer>

      <MainInfoActionsContainer>
        <Input
          testId="serial-number-input-update-inventory"
          onChange={(value?: string | number | null): void => {
            setState((prevState: State): State => ({
              ...prevState,
              valueSerialNumber: value,
            }));
          }}
          label={`${t('common.serialNumber')}`}
          placeholder={`${t('common.serialNumber')}`}
          value={state.valueSerialNumber}
          maxLength={35}
        />
      </MainInfoActionsContainer>

      <CustomerInfoContainer>
        <Paragraph weight={ParagraphWeight.bold}>{`${t('view.inventory.customerInfo')}`}</Paragraph>
      </CustomerInfoContainer>

      <CustomerInfoActionsContainer>
        <Input
          testId="customer-part-number-input-update-inventory"
          onChange={(value?: string | number | null): void => {
            setState((prevState: State): State => ({
              ...prevState,
              valueCustomerPartNumber: value,
            }));
          }}
          label={`${t('common.customerPartNumber')}`}
          placeholder={`${t('common.customerPartNumber')}`}
          value={state.valueCustomerPartNumber}
          maxLength={65}
          suffix={(
            <Tooltip
              text={`${t('view.inventory.create.tooltip.customerPartNumber')}`}
              placement={TooltipPlacement.left}
            >
              <CircleInfo />
            </Tooltip>
          )}
        />
      </CustomerInfoActionsContainer>
    </Drawer>
  );
}

export { UpdateInventory };
