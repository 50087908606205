// Core
import React, { useState } from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import { RequestMethod, requestVantageApi } from '../../../../../plugins/request';

// Media
import mockedCustomerLogo from '../../../../../media/images/logoSuperior.png';

// Components
import { Drawer } from '../../../../../layout/elements/Drawer';
import { Input } from '../../../../../layout/elements/Input';
import { Paragraph, ParagraphWeight } from '../../../../../layout/elements/Paragraph';
import {
  Select,
  SelectSizeType,
  SelectTagsMode,
} from '../../../../../layout/elements/Select';
import { showToast, ToastType } from '../../../../../layout/elements/Toast';
import { AtLine } from '../../../../../layout/icons';

// View
import { UpdateCustomerProps } from './Update.types';
import {
  Image,
  ImageContainer,
  UpdateContainer,
} from './Update.style';

function UpdateCustomer({
  className,
  customer,
  isDrawerOpen,
  onCloseDrawer,
  onUpdateData,
  testId,
}: UpdateCustomerProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  /* ***********************************************************************************************
  *************************************** LOCAL STATES *********************************************
  *********************************************************************************************** */

  interface State {
    isLoading: number | true | null;
    name?: string | number | null;
    domains?: string | string [] | null;
  }

  const initialState: State = {
    isLoading: null,
    name: customer.name,
    domains: customer.domains,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  *********************************************** METHODS ******************************************
  *********************************************************************************************** */

  /* Starts loading the Drawer */
  const startDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: true,
    }));
  };

  /* Finish loading the Drawer */
  const stopDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: 100,
    }));
  };

  /* Update customer data */
  const onUpdate = async (): Promise<void> => {
    startDrawerLoading();

    try {
      await requestVantageApi.customer({
        method: RequestMethod.PUT,
        path: `/customer/${customer.id}`,
        body: {
          name: state.name,
          domains: state.domains,
        },
      });

      showToast({
        text: t('view.customers.update.customerUpdated', { customer: customer.name }),
        type: ToastType.success,
      });

      stopDrawerLoading();

      setTimeout((): void => {
        onUpdateData();
        onCloseDrawer();
      }, 300);
    } catch (error: unknown) {
      setState((prevState: State): State => ({ ...prevState, isLoading: null }));
    }
  };

  /* Trigger onFieldChange based on fieldId */
  const handleFieldChange = (fieldId: string, value: string | string[]): void => {
    setState((prevState: State): State => ({
      ...prevState,
      [fieldId]: value,
    }));
  };

  return (
    <Drawer
      testId={testId}
      className={className}
      title={`${t('common.edit')}`}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      primaryAction={{
        children: t('common.edit'),
        onClick: onUpdate,
        disabled: !customer.name || !customer.domains.length,
        loading: state.isLoading,
        loadingText: `${t('common.editing')}`,
      }}
      secondaryAction={{ children: t('common.cancel'), onClick: onCloseDrawer }}
    >
      <UpdateContainer>
        <ImageContainer>
          <Image
            data-testid="customer-logo-preview"
            src={mockedCustomerLogo}
            alt={customer.name}
          />
        </ImageContainer>
        <div>
          <Paragraph
            testId="main-info-section"
            weight={ParagraphWeight.bold}
          >
            {t('common.mainInfo')}
          </Paragraph>
        </div>
        <div>
          <Input
            placeholder={`${t('common.name')}`}
            label={`${t('common.name')}`}
            value={state.name}
            onChange={(newValue) => {
              if (typeof newValue === 'string') {
                handleFieldChange('name', newValue);
              }
            }}
            maxLength={65}
            testId="input-customer-name"
          />
        </div>
        <div>
          <Select
            label={`${t('view.customers.update.domain')}`}
            mode={SelectTagsMode.tags}
            placeholder={`${t('view.customers.update.placeholderDomain')}`}
            onChange={(value: string | string[]) => handleFieldChange('domains', value)}
            size={SelectSizeType.lg}
            value={state.domains ? state.domains : undefined}
            supportingText={`${t('view.customers.update.domainsSupportingText')}`}
            prefix={<AtLine />}
          />
        </div>
      </UpdateContainer>
    </Drawer>
  );
}

export { UpdateCustomer };
