// Core
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import { formatNumber, roundNumber } from '../../plugins/general';

// Components
import { Col } from '../../layout/elements/Col';
import {
  Paragraph,
  ParagraphAlign,
  ParagraphSize,
  ParagraphWeight,
} from '../../layout/elements/Paragraph';
import { Row } from '../../layout/elements/Row';
import { Tooltip } from '../../layout/elements/Tooltip';

// Component
import {
  CardEquipmentComponent,
  ContentContainer,
  ContentRegisterModels,
  EquipmentIndicators,
  ImageEquipment,
  NameEquipmentContainer,
} from './CardEquipment.style';
import { CardEquipmentProps } from './CardEquipment.types';

function CardEquipment({
  className,
  imgSrc,
  onClick,
  registeredModels = 0,
  registeredUnits = 0,
  testId,
  title,
}: CardEquipmentProps): JSX.Element {
  const { t } = useTranslation();

  /* ***********************************************************************************************
  ************************************ INITIAL STATES **********************************************
  * ********************************************************************************************* */

  const [renderEquipmentNameTooltip, setRenderEquipmentNameTooltip] = useState<boolean>(false);

  const equipmentNameContentRef = useRef<HTMLDivElement | null>(null);
  const equipmentNameContainerRef = useRef<HTMLDivElement | null>(null);

  /* ***********************************************************************************************
  ************************************** COMPONENT HANDLING ****************************************
  * ********************************************************************************************* */

  const formattedRegisteredModelsCount: string = useMemo((): string => formatNumber(
    roundNumber(registeredModels),
  ), [registeredModels]);
  const formattedRegisteredUnitsCount: string = useMemo((): string => formatNumber(
    roundNumber(registeredUnits),
  ), [registeredUnits]);

  const updateEquipmentNameTooltipRender = useCallback((): void => {
    if (equipmentNameContentRef.current && equipmentNameContainerRef.current) {
      const equipmentNameContentWidth: number = equipmentNameContentRef.current.scrollWidth;
      const equipmentNameContainerWidth: number = parseFloat(
        getComputedStyle(equipmentNameContainerRef.current).width,
      );

      setRenderEquipmentNameTooltip(equipmentNameContentWidth >= equipmentNameContainerWidth);
    }
  }, []);

  useEffect((): () => void => {
    updateEquipmentNameTooltipRender();
    window.addEventListener('resize', updateEquipmentNameTooltipRender);
    return (): void => {
      window.removeEventListener('resize', updateEquipmentNameTooltipRender);
    };
  }, [updateEquipmentNameTooltipRender]);

  const renderEquipmentName = useMemo((): JSX.Element => (
    <NameEquipmentContainer ref={equipmentNameContainerRef}>
      <ContentContainer ref={equipmentNameContentRef}>
        <Paragraph weight={ParagraphWeight.bold} preventOverflow>
          {title}
        </Paragraph>
      </ContentContainer>
    </NameEquipmentContainer>
  ), [title]);

  return (
    <CardEquipmentComponent
      className={className}
      data-testid={testId}
      onClick={onClick}
    >
      <ImageEquipment alt={title} src={imgSrc} />
      {
        renderEquipmentNameTooltip
          ? <Tooltip text={title}>{renderEquipmentName}</Tooltip>
          : renderEquipmentName
      }
      <EquipmentIndicators>
        <Row>
          <Col cols={16}>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.regular}
            >
              {t('component.cardEquipment.registeredModels')}
            </Paragraph>
          </Col>
          <Col cols={8}>
            <ContentRegisterModels>
              <Paragraph
                align={ParagraphAlign.right}
                size={ParagraphSize.sm}
                weight={ParagraphWeight.bold}
              >
                {formattedRegisteredModelsCount}
              </Paragraph>
            </ContentRegisterModels>
          </Col>
        </Row>
        <Row>
          <Col cols={16}>
            <Paragraph
              size={ParagraphSize.sm}
              weight={ParagraphWeight.regular}
            >
              {t('component.cardEquipment.registeredUnits')}
            </Paragraph>
          </Col>
          <Col cols={8}>
            <Paragraph
              align={ParagraphAlign.right}
              size={ParagraphSize.sm}
              weight={ParagraphWeight.bold}
            >
              {formattedRegisteredUnitsCount}
            </Paragraph>
          </Col>
        </Row>
      </EquipmentIndicators>
    </CardEquipmentComponent>
  );
}

export { CardEquipment };
