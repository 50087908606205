// Core
import styled from 'styled-components';

/* ************** SELECT CATEGORY ******************* */

export const DescriptionContainer = styled.div`
  padding: 12px 0 20px 0;
`;

export const DescriptionContent = styled.div`
  padding-bottom: 20px;
`;

export const SelectEquipmentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ActionsStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

/* ************** CREATE ******************* */

export const MainInfoActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CustomerInfoContainer = styled.div`
  padding: 24px 0 20px 0;
`;

export const CustomerInfoActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

/* ************** IMPORT ******************* */

export const ButtonContainer = styled.div`
  padding: 8px 0 36px 0;
  width: 50%;
`;

export const FileUploadContainer = styled.div`
  padding: 20px 0 12px 0;
`;
