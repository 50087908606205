// Core
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  NavigateFunction,
  useNavigate,
  useParams,
} from 'react-router-dom';

// Libraries
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

// Store
import {
  setTableConfigs,
  TableColumnConfigs,
  TableConfigs,
  useLayoutStore,
} from '../../../../store/layout.store';

// Types
import {
  EquipmentCategory,
  EquipmentModel,
  EquipmentModelParameter,
} from '../../../../types/global';

// Plugins
import {
  capitalizeFirstLetter,
  DASHBOARD_NULLISH_TEXT,
  formatNumber,
  timestampToDateStrText,
} from '../../../../plugins/general';
import { RequestMethod, requestVantageApi } from '../../../../plugins/request';

// Locale
import i18n from '../../../../locale/i18n';

// Media
import Crusher from '../../../../media/images/equipment/crusher.png';

// Components
import { AdminPanel } from '../../../../layout/AdminPanel';
import { Breadcrumb, BreadcrumbItem } from '../../../../layout/elements/Breadcrumb';
import { Button, ButtonSize } from '../../../../layout/elements/Button';
import { Col } from '../../../../layout/elements/Col';
import { Checkbox } from '../../../../layout/elements/Checkbox';
import { Input } from '../../../../layout/elements/Input';
import {
  OptionsDropdown,
  OptionsDropdownColor,
  OptionsDropdownPlacement,
} from '../../../../layout/elements/OptionsDropdown';
import { PageHeader } from '../../../../layout/elements/PageHeader';
import { PaginationFooter } from '../../../../layout/elements/PaginationFooter';
import { Row } from '../../../../layout/elements/Row';
import { Table, TableColumnProps, TableSortOrder } from '../../../../layout/elements/Table';
import { TableColumnLayout } from '../../../../layout/elements/TableColumnLayout';
import {
  Edit,
  TrashCan,
  View,
} from '../../../../layout/icons';

// View
import {
  ActionsContainer,
  ButtonContainer,
  Container,
  InputContainer,
  TableContainer,
} from './EquipmentModelTable.style';

// Drawers
import { EquipmentModelRead } from '../EquipmentModelRead';
import { EquipmentModelCreate } from '../EquipmentModelCreate';
import { EquipmentModelDelete } from '../EquipmentModelDelete';
import { EquipmentModelUpdate } from '../EquipmentModelUpdate';

function EquipmentModelTable(): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const { equipmentCategory } = useParams<{ equipmentCategory: EquipmentCategory }>();
  const navigate: NavigateFunction = useNavigate();

  if (_.isNil(equipmentCategory)) {
    navigate('/equipment-models');
  }

  /* **********************************************************************************************
  **************************************** INITIAL STATE *****************************************
  * ******************************************************************************************** */

  enum DrawerOperation {
    create = 'create',
    update = 'update',
    delete = 'delete',
    view = 'view',
  }

  interface EquipmentModelState {
    drawerOperation: DrawerOperation;
    equipmentModel: EquipmentModel | null;
    equipmentModels: EquipmentModel[];
    isDrawerOpen: boolean;
    isLoading: boolean;
    pagination: { currentPage: number; totalItems: number; pageSize: number | null };
    searchValue: string | null;
  }

  const initialState: EquipmentModelState = {
    drawerOperation: DrawerOperation.create,
    equipmentModel: null,
    equipmentModels: [],
    isDrawerOpen: false,
    isLoading: true,
    pagination: { currentPage: 1, totalItems: 0, pageSize: null },
    searchValue: null,
  };

  const [state, setState] = useState<EquipmentModelState>(initialState);

  const TABLE_ID: string = 'equipment-models-table';
  const tableConfigs: TableConfigs = useLayoutStore(
    (tableConfigsState) => tableConfigsState.tablesConfigs[TABLE_ID],
  );

  /* **********************************************************************************************
  ******************************************* METHODS ********************************************
  * ******************************************************************************************** */

  const fetchEquipmentModels = useCallback(async (): Promise<void> => {
    let response: EquipmentModel[] = [];
    try {
      const apiResponse: EquipmentModel[] = await requestVantageApi.equipmentModel({
        method: RequestMethod.GET,
        path: `/${equipmentCategory}`,
      });
      response = apiResponse.map((item: EquipmentModel) => ({
        ...item,
        key: item.id,
        picture: Crusher, // @TODO: Add real picture from API, when available
      }));
    } catch (error) {
      console.error('Failed to fetch equipment models', error);
    }

    setState((prevState: EquipmentModelState) => ({
      ...prevState,
      isLoading: false,
      equipmentModels: response,
      pagination: {
        ...prevState.pagination,
        totalItems: response.length,
      },
    }));
  }, [equipmentCategory]);

  /* **********************************************************************************************
  ************************************** COMPONENT HANDLING ***************************************
  * ******************************************************************************************** */

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    {
      target: '/equipment-models',
      text: i18n.t('view.equipmentModel.title'),
    },
    {
      target: '/equipment-models-table',
      text: i18n.t('view.equipmentModel.equipmentModelTable.header.title', {
        equipmentCategory: capitalizeFirstLetter(equipmentCategory),
      }),
    },
  ], [equipmentCategory]);

  const getParameterValue = (
    record: any,
    paramName: string,
  ): string => {
    const value = record.parameters.find((
      p: EquipmentModelParameter,
    ): boolean => p.name === paramName)?.value;

    return value ? formatNumber(value) : DASHBOARD_NULLISH_TEXT;
  };

  const columns: TableColumnProps[] = useMemo(() => [
    {
      title: <Checkbox disabled />, // @TODO Apply in the future
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: () => <Checkbox disabled />,
    },
    {
      title: `${i18n.t('common.picture')}`,
      dataIndex: 'picture',
      key: 'picture',
      render: (text: string) => <img src={text} alt={text} />,
    },
    {
      title: `${i18n.t('common.superiorPartNumber')}`,
      dataIndex: 'superiorPartNumber',
      key: 'superiorPartNumber',
    },
    {
      title: `${i18n.t('common.commercialName')}`,
      dataIndex: 'commercialName',
      key: 'commercialName',
    },
    {
      title: `${i18n.t('common.createdAt')}`,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      defaultSortOrder: TableSortOrder.ascend,
      render: (record: any) => timestampToDateStrText(record),
    },
    {
      title: `${i18n.t('equipment.crusher.parameters.headDiameter')}`,
      dataIndex: 'parameters',
      key: 'headDiameter',
      render: (_text: string, record: any) => getParameterValue(record, 'headDiameter'),
    },
    {
      title: `${i18n.t('equipment.crusher.parameters.weight')}`,
      dataIndex: 'parameters',
      key: 'weight',
      render: (_text: string, record: any) => getParameterValue(record, 'weight'),
    },
    {
      title: `${i18n.t('equipment.crusher.parameters.recommendedHP')}`,
      dataIndex: 'parameters',
      key: 'recommendedHP',
      render: (_text: string, record: any) => getParameterValue(record, 'recommendedHP'),
    },
    {
      title: `${i18n.t('equipment.crusher.parameters.maxFeedOpening')}`,
      dataIndex: 'parameters',
      key: 'maxFeedOpening',
      render: (_text: string, record: any) => getParameterValue(record, 'maxFeedOpening'),
    },
    {
      title: `${i18n.t('equipment.crusher.parameters.csS')}`,
      dataIndex: 'parameters',
      key: 'csS',
      render: (_text: string, record: any) => getParameterValue(record, 'csS'),
    },
    {
      dataIndex: 'dropdown',
      key: 'dropdown',
      render: (_text: string, record: EquipmentModel) => (
        <OptionsDropdown
          testId="options-dropdown"
          placement={OptionsDropdownPlacement.bottomRight}
          options={[
            {
              text: i18n.t('common.view'),
              icon: <View />,
              color: OptionsDropdownColor.normal,
              disabled: false,
              onClick: (): void => {
                setState((prevState: EquipmentModelState) => ({
                  ...prevState,
                  equipmentModel: record,
                  isDrawerOpen: true,
                  drawerOperation: DrawerOperation.view,
                }));
              },
            },
            {
              text: i18n.t('common.edit'),
              icon: <Edit />,
              color: OptionsDropdownColor.normal,
              disabled: false,
              onClick: (): void => {
                setState((prevState: EquipmentModelState) => ({
                  ...prevState,
                  equipmentModel: record,
                  isDrawerOpen: true,
                  drawerOperation: DrawerOperation.update,
                }));
              },
            },
          ]}
          secondaryOptions={[
            {
              text: i18n.t('common.delete'),
              icon: <TrashCan />,
              color: OptionsDropdownColor.danger,
              disabled: false,
              onClick: (): void => {
                setState((prevState: EquipmentModelState) => ({
                  ...prevState,
                  equipmentModel: record,
                  isDrawerOpen: true,
                  drawerOperation: DrawerOperation.delete,
                }));
              },
            },
          ]}
        />
      ),
    },
  ], [DrawerOperation]);

  const visibleColumns: TableColumnProps[] = useMemo(() => {
    if (!tableConfigs?.columns || tableConfigs.columns.length === 0) {
      setTableConfigs({
        id: TABLE_ID,
        columns: columns.map((column: TableColumnProps) => ({ id: column.key, visible: true })),
      });
      return columns;
    }

    return columns.filter((column: TableColumnProps) => {
      const columnConfig = tableConfigs.columns.find(
        (config: TableColumnConfigs) => config.id === column.key,
      );
      return columnConfig && columnConfig.visible;
    });
  }, [columns, tableConfigs, TABLE_ID]);

  const toggleColumnVisibility = useCallback((selected: string[]): void => {
    const newColumns = columns.map((column): TableColumnProps & { visible: boolean } => ({
      ...column,
      visible: selected.includes(column.key),
    }));
    setTableConfigs({
      id: TABLE_ID,
      columns: newColumns.map((column) => ({ id: column.key, visible: column.visible })),
    });
  }, [columns, TABLE_ID]);

  const onSearchData = useCallback((value: string | null): void => {
    setState((prevState: EquipmentModelState) => ({
      ...prevState,
      searchValue: value ? value.trim() : null,
      pagination: {
        ...prevState.pagination,
        currentPage: 1,
      },
    }));
  }, []);

  const debouncedOnSearchData: _.DebouncedFunc<(value: (string | null)) => void> = _.debounce(
    onSearchData,
    300,
  );

  const filteredData: EquipmentModel[] = useMemo(() => {
    if (_.isNil(state.searchValue) || state.searchValue === '') {
      return state.equipmentModels;
    }

    const lowerCaseSearchValue: string = state.searchValue.toLowerCase();
    return state.equipmentModels.filter((item) => Object.values(item).some(
      (value) => !_.isNil(value)
        && value.toString().toLowerCase().includes(lowerCaseSearchValue),
    ));
  }, [state.equipmentModels, state.searchValue]);

  const handlePageChange = useCallback((currentPage: number, pageSize: number): void => {
    setState((prevState: EquipmentModelState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        currentPage,
        pageSize,
      },
    }));
  }, []);

  const paginatedData: EquipmentModel[] = useMemo(() => {
    const { currentPage, pageSize } = state.pagination;

    let data: EquipmentModel[] = filteredData;

    if (pageSize) {
      const startIndex: number = (currentPage - 1) * pageSize;
      const endIndex: number = startIndex + pageSize;
      data = data.slice(startIndex, endIndex);
    }

    return data;
  }, [filteredData, state.pagination]);

  // Create Drawer for each operation
  const mountEquipmentModelDrawers: Record<DrawerOperation, JSX.Element> = {
    [DrawerOperation.create]: (
      <EquipmentModelCreate
        isDrawerOpen={state.isDrawerOpen}
        onCloseDrawer={
          (): void => setState((prevState: EquipmentModelState) => ({
            ...prevState,
            isDrawerOpen: false,
          }))
        }
        updateEquipmentModels={fetchEquipmentModels}
      />
    ),
    [DrawerOperation.update]: (
      <EquipmentModelUpdate
        equipmentModel={state.equipmentModel}
        isDrawerOpen={state.isDrawerOpen}
        onCloseDrawer={
          (): void => setState((prevState: EquipmentModelState) => ({
            ...prevState,
            isDrawerOpen: false,
            equipmentModel: null,
          }))
        }
        updateEquipmentModels={fetchEquipmentModels}
      />
    ),
    [DrawerOperation.delete]: (
      <EquipmentModelDelete
        equipmentModel={state.equipmentModel}
        isDrawerOpen={state.isDrawerOpen}
        onCloseDrawer={
          (): void => setState((prevState: EquipmentModelState) => ({
            ...prevState,
            isDrawerOpen: false,
            equipmentModel: null,
          }))
        }
        updateEquipmentModels={fetchEquipmentModels}
      />
    ),
    [DrawerOperation.view]: (
      <EquipmentModelRead
        equipmentModel={state.equipmentModel}
        isDrawerOpen={state.isDrawerOpen}
        onCloseDrawer={
          (): void => setState((prevState: EquipmentModelState) => ({
            ...prevState,
            isDrawerOpen: false,
            equipmentModel: null,
          }))
        }
        onOpenDelete={
          (): void => setState((prevState: EquipmentModelState) => ({
            ...prevState,
            isDrawerOpen: true,
            drawerOperation: DrawerOperation.delete,
          }))
        }
        onOpenEdit={
          (): void => setState((prevState: EquipmentModelState) => ({
            ...prevState,
            isDrawerOpen: true,
            drawerOperation: DrawerOperation.update,
          }))
        }
      />
    ),
  };

  // Fetch Equipment Models
  useEffect((): void => {
    fetchEquipmentModels();
  }, [fetchEquipmentModels]);

  // Handle pagination
  useEffect(() => {
    const totalItems: number = filteredData.length;
    const pageSize: number = state.pagination.pageSize || totalItems || 1;
    const totalPages: number = Math.ceil(totalItems / pageSize);

    if (state.pagination.currentPage > totalPages && totalPages > 0) {
      setState((prevState: EquipmentModelState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          currentPage: 1,
          totalItems,
        },
      }));
    } else {
      setState((prevState: EquipmentModelState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          totalItems,
        },
      }));
    }
  }, [filteredData, state.pagination.pageSize, state.pagination.currentPage]);

  return (
    <AdminPanel>
      <Container>
        <Breadcrumb items={breadcrumbItems} />
        <PageHeader
          title={
            t(
              'view.equipmentModel.equipmentModelTable.header.title',
              { equipmentCategory: capitalizeFirstLetter(equipmentCategory) },
            )
          }
          description={
            `${t(
              `view.equipmentModel.equipmentModelTable.header.${
                state.equipmentModels.length > 1
                  ? 'descriptionPlural'
                  : 'description'
              }`,
              {
                equipmentCategory: capitalizeFirstLetter(equipmentCategory),
                count: state.equipmentModels.length,
              },
            )}`
          }
        />
        <Row>
          <Col>
            <ActionsContainer>
              <InputContainer>
                <Input
                  onChange={
                    (searchValueInput: string | number | null | undefined) => {
                      debouncedOnSearchData(searchValueInput as string);
                    }
                  }
                  value={state.searchValue}
                  placeholder={
                    `${t(
                      'view.equipmentModel.equipmentModelTable.input.textInput',
                      { equipmentCategory: capitalizeFirstLetter(equipmentCategory) },
                    )}`
                  }
                />
              </InputContainer>
              <ButtonContainer>
                <TableColumnLayout
                  options={
                    columns
                      .filter((column: TableColumnProps) => typeof column.title === 'string')
                      .map((column: TableColumnProps) => ({
                        key: column.key,
                        text: column.title as string,
                      }))
                  }
                  onSelect={(selected) => toggleColumnVisibility(selected as string[])}
                  selected={
                    tableConfigs && (
                      tableConfigs.columns.filter((col) => col.visible).map((col) => col.id) || []
                    )
                  }
                  testId="table-column-layout"
                />
                {/* <Button */}
                {/*  block */}
                {/*  square */}
                {/*  onClick={() => {}} */}
                {/*  size={ButtonSize.xs} */}
                {/*  type={ButtonType.secondary} */}
                {/*  disabled */}
                {/* > */}
                {/*  <UploadLine /> */}
                {/* </Button> */}
                <Button
                  onClick={(): void => setState((prevState: EquipmentModelState) => ({
                    ...prevState,
                    isDrawerOpen: true,
                    drawerOperation: DrawerOperation.create,
                  }))}
                  size={ButtonSize.xs}
                  block={false}
                >
                  {t('common.newModel')}
                </Button>
              </ButtonContainer>
            </ActionsContainer>
          </Col>
        </Row>
        <TableContainer>
          <Table columns={visibleColumns} rows={paginatedData} testId="table" />
        </TableContainer>
        {mountEquipmentModelDrawers[state.drawerOperation]}
      </Container>
      <PaginationFooter
        currentPage={state.pagination.currentPage}
        onPageChange={handlePageChange}
        totalItems={state.pagination.totalItems}
        testId="pagination-footer"
      />
    </AdminPanel>
  );
}

export { EquipmentModelTable };
