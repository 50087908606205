// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import { formatNumber, roundNumber } from '../../../../plugins/general';

// Types
import { Theme } from '../../../../types/theme';

// Plugins
import { lastOperationalUnitData } from '../../../../plugins/crusher';

// Components
import { Paragraph, ParagraphWeight } from '../../../../layout/elements/Paragraph';
import {
  Title,
  TitleSize,
  TitleWeight,
} from '../../../../layout/elements/Title';

// View
import {
  LinerWearContainer,
  LinerWearContent,
  LinerWearUnitContainer,
  LinerWearValueContainer,
} from './LinerWearModule.style';
import { CrusherLinerWearModuleProps } from './LinerWearModule.types';

function LinerWearModule({
  className,
  decimalPlaces = 2,
  operationalData,
  testId,
}: CrusherLinerWearModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData = lastOperationalUnitData(operationalData);

  // Format the linerWear.value to specified decimal places
  const roundedValue: number | null | undefined = roundNumber(
    lastUnitData?.linerWear,
    decimalPlaces,
  );

  return (
    <LinerWearContainer
      data-testid={testId}
      className={className}
    >
      <Paragraph weight={ParagraphWeight.bold}>
        {t('view.crusher.linerWear')}
      </Paragraph>
      <LinerWearContent>
        <LinerWearValueContainer>
          <Title
            size={TitleSize.xxs}
            weight={TitleWeight.medium}
          >
            {(formatNumber(roundedValue))}
          </Title>
        </LinerWearValueContainer>
        <LinerWearUnitContainer>
          <Paragraph
            color={theme.color.crusherDashboard.lineWear.unitTextColor}
          >
            {/* @TODO: apply the dynamic unit and remove this hardcoded */}
            mm
          </Paragraph>
        </LinerWearUnitContainer>
      </LinerWearContent>
    </LinerWearContainer>
  );
}

export { LinerWearModule };
