// Core
import React, { useState } from 'react';

// Components
import { Ellipsis } from '../../icons';

// Component
import {
  Options,
  OptionsDropdownItems,
  OptionsDropdownPlacement,
  OptionsDropdownProps,
} from './OptionsDropdown.types';
import {
  Container,
  Divider,
  Items,
  ListContainer,
  MenuItemsContainer,
} from './OptionsDropdown.style';

function OptionsDropdown({
  children = <Ellipsis />,
  className,
  options,
  placement = OptionsDropdownPlacement.bottom,
  secondaryOptions,
  testId,
}: OptionsDropdownProps) {
  const [open, setOpen] = useState<boolean>(false);

  const handleCloseDropdown = (): void => {
    setOpen(false);
  };

  const menuItems = (items: Options[]) => items.map(({
    color,
    disabled,
    icon,
    text,
    onClick,
  }): OptionsDropdownItems => ({
    key: text,
    label: (
      <Items
        className="options-dropdown-items"
        color={color}
        disabled={disabled}
        onClick={disabled ? undefined : (e: React.MouseEvent<HTMLElement>): void => {
          onClick?.(e);
          handleCloseDropdown();
        }}
      >
        {icon}
        {text}
      </Items>
    ),
  }));

  const primaryItems: OptionsDropdownItems[] = menuItems(options);

  const secondaryItems: {
    key: string,
    label: React.ReactNode,
  }[] = menuItems(secondaryOptions || []);

  const items: (OptionsDropdownItems | { key: string, type: string })[] = [
    ...primaryItems,
    { key: 'separator', type: 'separator' },
    ...secondaryItems,
  ];

  const renderDropdown = (): JSX.Element => (
    <ListContainer>
      {primaryItems.map((item: OptionsDropdownItems) => (
        <MenuItemsContainer className="dropdown-container" key={item.key}>{item.label}</MenuItemsContainer>
      ))}
      {secondaryItems.length > 0 && <Divider />}
      {secondaryItems.map((item: OptionsDropdownItems) => (
        <MenuItemsContainer key={item.key}>{item.label}</MenuItemsContainer>
      ))}
    </ListContainer>
  );

  return (
    <div className={className} data-testid={testId}>
      <Container
        menu={{ items }}
        placement={placement}
        dropdownRender={renderDropdown}
        onOpenChange={setOpen}
        open={open}
      >
        <div>{children}</div>
      </Container>
    </div>
  );
}

export { OptionsDropdown };
