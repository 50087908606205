// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Types
import { Theme } from '../../../../../types/theme';

// Media
import ImageCrusher from '../../../../../media/images/equipment/crusher.png';

// Components
import { Col } from '../../../../../layout/elements/Col';
import { Drawer } from '../../../../../layout/elements/Drawer';
import { Edit, TrashCan } from '../../../../../layout/icons';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';
import { Row } from '../../../../../layout/elements/Row';

// View
import { DrawerOperation } from '../../Inventory.types';
import { ReadInventoryProps } from './Read.types';
import {
  CustomerInfoContainer,
  CustomerInformationTitle,
  MainInfoContainer,
  MainInformationTitle,
} from './Read.style';

function ReadInventory({
  className,
  customer,
  equipmentModel,
  equipmentUnit,
  isDrawerOpen,
  onCloseDrawer,
  onRead,
  testId,
}: ReadInventoryProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const theme: Theme = useTheme();

  return (
    <Drawer
      testId={testId}
      className={className}
      title={t(`common.equipmentCategory.${equipmentUnit.equipmentCategory}.singular`)}
      onClose={onCloseDrawer}
      open={isDrawerOpen}
      footer={false}
      preview={{
        previewImage: ImageCrusher, // @TODO: Load image dynamically from backend in future
        primaryPreviewAction: {
          onClick: () => onRead(DrawerOperation.delete, equipmentUnit.id),
          children: <TrashCan />,
        },
        secondaryPreviewAction: {
          onClick: () => onRead(DrawerOperation.update, equipmentUnit.id),
          children: <Edit />,
        },
      }}
    >
      {equipmentUnit && (
      <div>

        <MainInfoContainer>
          <MainInformationTitle>
            <Paragraph
              weight={ParagraphWeight.bold}
              color={theme.color.inventory.descriptionColor}
            >
              {`${t('common.mainInfo')}`}
            </Paragraph>
          </MainInformationTitle>
          <Row>
            <Col cols={12}>
              <Paragraph
                size={ParagraphSize.sm}
                weight={ParagraphWeight.bold}
                color={theme.color.inventory.fieldTitleColor}
              >
                {`${t('common.serialNumber')}`}
              </Paragraph>
              <Paragraph color={theme.color.inventory.fieldTextColor}>
                {equipmentUnit.serialNumber}
              </Paragraph>
            </Col>
            <Col cols={12}>
              <Paragraph
                size={ParagraphSize.sm}
                weight={ParagraphWeight.bold}
                color={theme.color.inventory.fieldTitleColor}
              >
                {`${t('common.superiorPartNumber')}`}
              </Paragraph>
              <Paragraph
                color={theme.color.inventory.fieldTextColor}
              >
                {equipmentModel.superiorPartNumber}
              </Paragraph>
            </Col>
          </Row>
        </MainInfoContainer>

        <CustomerInfoContainer>
          <CustomerInformationTitle>
            <Paragraph
              weight={ParagraphWeight.bold}
              color={theme.color.inventory.descriptionColor}
            >
              {`${t('view.inventory.customerInfo')}`}
            </Paragraph>
          </CustomerInformationTitle>
          <Row>
            <Col cols={12}>
              <Paragraph
                size={ParagraphSize.sm}
                weight={ParagraphWeight.bold}
                color={theme.color.inventory.fieldTitleColor}
              >
                {`${t('common.customer')}`}
              </Paragraph>
              <Paragraph color={theme.color.inventory.fieldTextColor}>{customer.name}</Paragraph>
            </Col>
            <Col cols={12}>
              <Paragraph
                size={ParagraphSize.sm}
                weight={ParagraphWeight.bold}
                color={theme.color.inventory.fieldTitleColor}
              >
                {`${t('common.customerPartNumber')}`}
              </Paragraph>
              <Paragraph color={theme.color.inventory.fieldTextColor}>
                {equipmentUnit.customerPartNumber}
              </Paragraph>
            </Col>
          </Row>
        </CustomerInfoContainer>

      </div>
      )}
    </Drawer>
  );
}

export { ReadInventory };
