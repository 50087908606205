/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Components
import { Button } from '../../../../layout/elements/Button';

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
`;

export const CrusherStatus = styled.div`
  flex-direction: column;
  gap: 4px;
`;

export const EquipmentStatusContainer = styled.div`
  margin-bottom: 6px;
`;

export const EquipmentName = styled.div`
  > sup {
    margin-top: 4px;
    margin-right: 4px;
    font-family: ${({ theme }) => theme.font.bold};
    font-size: 16px;
  };
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: flex-end;
  width: 100%;
`;

export const OverviewContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const CrusherInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const AlarmButton = styled(Button)`
  position: relative;

  .alarms-indicator {
    position: absolute;
    left: 25px;
    bottom: 28px;
    width: 24px;
    height: 18px;
    color: ${({
      theme,
    }) => theme.color.crusherDashboard.detailsModule.activeAlarmTextColor};
    font-size: 12px;
    line-height: normal;
    font-family: ${({ theme }) => theme.font.medium};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: ${({
     theme,
    }) => theme.color.crusherDashboard.detailsModule.activeAlarmBackgroundColor};
  };
`;

export const DetailsContainer = styled.div`
  background-color: ${({ theme }) => theme.color.crusherDashboard.modulesBackgroundColor};
  padding: 16px 24px 20px;
  border-radius: 4px;
  flex: 1;
  display: flex;
`;

export const LastUpdateTime = styled.span`
  font-family: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.color.crusherDashboard.detailsModule.lastUpdateTimeTextColor};
`;
