// Core
import React, { useState } from 'react';

// Libraries
import { Trans, useTranslation } from 'react-i18next';

// Plugins
import { capitalizeFirstLetter } from '../../../../../plugins/general';
import { RequestMethod, requestVantageApi } from '../../../../../plugins/request';

// Components
import { DeleteWarningBox } from '../../../../../components/DeleteWarningBox';
import { Drawer } from '../../../../../layout/elements/Drawer';
import { Input } from '../../../../../layout/elements/Input';
import { Paragraph } from '../../../../../layout/elements/Paragraph';
import { ToastType, showToast } from '../../../../../layout/elements/Toast';

// View
import { DeleteInventoryProps } from './Delete.types';
import {
  DeleteActionContainer,
  DeleteWarningBoxContainer,
  InputContainer,
} from './Delete.style';

function DeleteInventory({
  className,
  customer,
  equipmentUnit,
  isDrawerOpen,
  onCloseDrawer,
  onUpdateData,
  testId,
}: DeleteInventoryProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  /* ***********************************************************************************************
  *************************************** LOCAL STATES *********************************************
  *********************************************************************************************** */

  interface State {
    isLoading: number | true | null;
    inputValue?: string | number | null;
  }

  const initialState: State = {
    isLoading: null,
    inputValue: null,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  *********************************************** METHODS ******************************************
  *********************************************************************************************** */

  const startDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: true,
    }));
  };

  const stopDrawerLoading = (): void => {
    setState((prevState: State): State => ({
      ...prevState,
      isLoading: 100,
    }));
  };

  /* Request */
  const onDelete = async (): Promise<void> => {
    startDrawerLoading();

    try {
      if (customer) {
        await requestVantageApi.equipmentUnit({
          method: RequestMethod.DELETE,
          path: `/crusher/inventory/${equipmentUnit.id}`,
          params: { idCustomer: customer.id },
        });

        if (equipmentUnit && equipmentUnit.equipmentCategory) {
          showToast({
            text: t('view.inventory.delete.deleteEquipment', {
              category: capitalizeFirstLetter(equipmentUnit.equipmentCategory),
            }),
            type: ToastType.success,
          });
        }
      }

      setState((prevState: State): State => ({
        ...prevState,
        isLoading: null,
      }));

      stopDrawerLoading();

      setTimeout((): void => {
        onUpdateData();
        onCloseDrawer();
      }, 300);

      setState((prevState: State): State => ({
        ...prevState,
        inputValue: null,
      }));
    } catch (error: unknown) {
      setState((prevState: State): State => ({
        ...prevState,
        isLoading: null,
      }));
    }
  };

  return (
    <Drawer
      testId={testId}
      className={className}
      title={t(
        'view.inventory.delete.deleteUnit',
        { category: t(`common.equipmentCategory.${equipmentUnit.equipmentCategory}.singular`) },
      )}
      onClose={onCloseDrawer}
      open={isDrawerOpen}
      primaryAction={{
        children: `${t('common.delete')}`,
        onClick: onDelete,
        loading: state.isLoading,
        loadingText: `${t('common.deleting')}`,
        disabled: (
          state.inputValue !== `${t('common.delete')}`.toUpperCase()
        ),
      }}
      secondaryAction={{ children: `${t('common.cancel')}`, onClick: onCloseDrawer }}
    >

      <DeleteWarningBoxContainer>
        {equipmentUnit && (
        <DeleteWarningBox
          testId="delete-warning-box-delete-inventory-drawer"
          itemIdentifier={{
            key: `${t('common.serialNumber')}`,
            value: equipmentUnit.serialNumber,
          }}
          itemType={`${t('common.device')}`}
        />
        )}
      </DeleteWarningBoxContainer>

      <DeleteActionContainer>
        <Paragraph>
          <Trans
            i18nKey="view.inventory.delete.confirmDelete"
            values={{ delete: `${t('common.delete')}`.toUpperCase() }}
            components={{ strong: <strong /> }}
          />
        </Paragraph>
        <InputContainer>
          <Input
            testId="delete-confirmation-inventory-input"
            placeholder={`${t('view.inventory.delete.labelDeleteInput')}`}
            onChange={(value?: string | number | null): void => {
              setState((prevState: State): State => ({
                ...prevState,
                inputValue: typeof value === 'string'
                  ? value.toUpperCase()
                  : value,
              }));
            }}
            label={`${t('view.inventory.delete.labelDeleteInput')}`}
            value={state.inputValue}
            maxLength={35}
          />
        </InputContainer>
      </DeleteActionContainer>

    </Drawer>
  );
}

export { DeleteInventory };
