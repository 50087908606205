// Core
import React from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Plugins
import { formatNumber } from '../../../../../plugins/general';
import {
  controlModeToTagType,
  controlModeToText,
  lastOperationalEdgeStatus,
  lastOperationalUnitData,
  permissiveToOnOffTagType,
  permissiveToOnOffText,
  runningStatusToTagType,
  runningStatusToText,
} from '../../../../../plugins/crusher';

// Types
import { EquipmentUnitDataCrusher } from '../../../../../types/crusher';
import {
  EdgeDeviceOperationalStatus,
  EdgeDeviceOperationalStatusStatus,
} from '../../../../../types/global';

// Components
import {
  AlertLine,
  // Play,
  // Stop
} from '../../../../../layout/icons';
import { AlertType } from '../../../../../layout/elements/Alert';
// import { Button, ButtonType } from '../../../../../layout/elements/Button';
import { Col } from '../../../../../layout/elements/Col';
import { Drawer, DrawerSize } from '../../../../../layout/elements/Drawer';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';
// import { PowerSwitch } from '../../../../../components/PowerSwitch';
import { Row } from '../../../../../layout/elements/Row';
// import { SwitchWithText } from '../../../../../layout/elements/SwitchWithText';
import { Tag } from '../../../../../layout/elements/Tag';
import { Thermometer } from '../../../../../layout/elements/Thermometer';

// Component
import { MotorControlProps } from './MotorControl.types';
import {
  BorderBottom,
  ControlModeContainer,
  LubeTankHeaterStatusContainer,
  // ControllerModuleContainer,
  // Controllers,
  // CrusherMotorContainer,
  // DischargeContainer,
  // ExecuteCommandContainer,
  // FeederContainer,
  // LubeTankHeaterContainer,
  // LubeUnitContainer,
  MonitoringContainer,
  MonitoringStatus,
  MonitoringStatusContainer,
  // MotorControlContainer,
  // StatusContainer,
  // StopStartButtonsContainer,
  ThermometerContainer,
} from './MotorControl.style';

function MotorControl({
  className,
  isDrawerOpen,
  onCloseDrawer,
  operationalData,
  testId,
}: MotorControlProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  const lastEdgeStatus: EdgeDeviceOperationalStatus | null = lastOperationalEdgeStatus(
    operationalData,
  );

  return (
    <Drawer
      testId={testId}
      className={className}
      title={t('view.crusher.motorControl.title')}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      alert={
        lastEdgeStatus?.edgeStatus !== EdgeDeviceOperationalStatusStatus.on
        && {
          type: AlertType.warning,
          icon: <AlertLine />,
          description: `${t('layout.elements.drawer.alertText')}`,
        }
      }
      footer={false}
      size={DrawerSize.lg}
    >
      <Row>
        <Col>
          <Paragraph weight={ParagraphWeight.bold}>
            {t('view.crusher.motorControl.drawer.monitoring')}
          </Paragraph>
        </Col>
      </Row>
      <MonitoringContainer>
        <BorderBottom>
          <Row>
            <Col>
              <ControlModeContainer>
                <Paragraph
                  size={ParagraphSize.sm}
                  weight={ParagraphWeight.medium}
                >
                  {t('view.crusher.motorControl.title')}
                </Paragraph>
                <Tag
                  text={controlModeToText(lastUnitData?.motorControl)}
                  type={
                    controlModeToTagType(
                      lastUnitData?.motorControl,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                />
              </ControlModeContainer>
            </Col>
          </Row>
        </BorderBottom>
        <Row>
          <Col cols={16}>
            <MonitoringStatusContainer>
              <MonitoringStatus>
                <Paragraph
                  weight={ParagraphWeight.medium}
                  size={ParagraphSize.sm}
                >
                  {t('view.crusher.motorControl.drawer.lubeUnitRunning')}
                </Paragraph>
                <Tag
                  text={runningStatusToText(lastUnitData?.lubePumpRunningStatus)}
                  type={
                    runningStatusToTagType(
                      lastUnitData?.lubePumpRunningStatus,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                />
              </MonitoringStatus>
              <Paragraph
                weight={ParagraphWeight.bold}
                size={ParagraphSize.xl}
              >
                {lastUnitData?.lubePump1RunHours === 1
                  ? t('dates.hour.singular')
                  : t('dates.hour.plural', {
                    hours: formatNumber(lastUnitData?.lubePump1RunHours),
                  })}
              </Paragraph>
            </MonitoringStatusContainer>
            <MonitoringStatusContainer>
              <MonitoringStatus>
                <Paragraph
                  weight={ParagraphWeight.medium}
                  size={ParagraphSize.sm}
                >
                  {t('view.crusher.motorControl.drawer.dischargeConveyorRunning')}
                </Paragraph>
                <Tag
                  text={runningStatusToText(lastUnitData?.dischargeRunningStatus)}
                  type={
                    runningStatusToTagType(
                      lastUnitData?.dischargeRunningStatus,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                />
              </MonitoringStatus>
              <Paragraph
                weight={ParagraphWeight.bold}
                size={ParagraphSize.xl}
              >
                {lastUnitData?.dischargeRunHours === 1
                  ? t('dates.hour.singular')
                  : t('dates.hour.plural', {
                    hours: formatNumber(lastUnitData?.dischargeRunHours),
                  })}
              </Paragraph>
            </MonitoringStatusContainer>
            <MonitoringStatusContainer>
              <MonitoringStatus>
                <Paragraph
                  weight={ParagraphWeight.medium}
                  size={ParagraphSize.sm}
                >
                  {t('view.crusher.motorControl.drawer.crusherMotorRunning')}
                </Paragraph>
                <Tag
                  text={runningStatusToText(lastUnitData?.crusherMotor)}
                  type={
                    runningStatusToTagType(
                      lastUnitData?.crusherMotor,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                />
              </MonitoringStatus>
              <Paragraph
                weight={ParagraphWeight.bold}
                size={ParagraphSize.xl}
              >
                {lastUnitData?.crusherMotor1RunHours === 1
                  ? t('dates.hour.singular')
                  : t('dates.hour.plural', {
                    hours: formatNumber(lastUnitData?.crusherMotor1RunHours),
                  })}
              </Paragraph>
            </MonitoringStatusContainer>
            <MonitoringStatusContainer>
              <MonitoringStatus>
                <Paragraph
                  weight={ParagraphWeight.medium}
                  size={ParagraphSize.sm}
                >
                  {t('view.crusher.motorControl.drawer.feederConveyorRunning')}
                </Paragraph>
                <Tag
                  text={runningStatusToText(lastUnitData?.feederRunningStatus)}
                  type={
                    runningStatusToTagType(
                      lastUnitData?.feederRunningStatus,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                />
              </MonitoringStatus>
              <Paragraph
                weight={ParagraphWeight.bold}
                size={ParagraphSize.xl}
              >
                {lastUnitData?.feederRunHours === 1
                  ? t('dates.hour.singular')
                  : t('dates.hour.plural', {
                    hours: formatNumber(lastUnitData?.feederRunHours),
                  })}
              </Paragraph>
            </MonitoringStatusContainer>
          </Col>
          <Col cols={8}>
            <ThermometerContainer>
              <Thermometer
                running={lastEdgeStatus?.edgeStatus === EdgeDeviceOperationalStatusStatus.on}
                minValue={-20}
                maxValue={150}
                value={lastUnitData?.lubricationTankTemperature}
                thresholds={{
                  lowAlarm: 17,
                  lowWarning: 21,
                  highWarning: 54,
                  highAlarm: 60,
                }}
                unit="ºC"
                title={`${t('view.crusher.motorControl.drawer.lubeTankTemp')}`}
              />
            </ThermometerContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <LubeTankHeaterStatusContainer>
              <MonitoringStatus>
                <Paragraph
                  weight={ParagraphWeight.medium}
                  size={ParagraphSize.sm}
                >
                  {t('view.crusher.motorControl.drawer.lubeTankHeater')}
                </Paragraph>
                <Tag
                  text={permissiveToOnOffText(lastUnitData?.lubeTankHeaterEnable)}
                  type={
                    permissiveToOnOffTagType(
                      lastUnitData?.lubeTankHeaterEnable,
                      lastEdgeStatus ? lastEdgeStatus.edgeStatus : null,
                    )
                  }
                />
              </MonitoringStatus>
            </LubeTankHeaterStatusContainer>
          </Col>
        </Row>
      </MonitoringContainer>
      {/* @TODO: back this flow when add control */}
      {/* <Row> */}
      {/*  <Col> */}
      {/*    <Paragraph weight={ParagraphWeight.bold}> */}
      {/*      {t('view.crusher.motorControl.drawer.controllers')} */}
      {/*    </Paragraph> */}
      {/*  </Col> */}
      {/* </Row> */}
      {/* <ControllerModuleContainer> */}
      {/*  <BorderBottom> */}
      {/*    <Row> */}
      {/*      <Col cols={12}> */}
      {/*        <MotorControlContainer> */}
      {/*          <Paragraph weight={ParagraphWeight.bold}> */}
      {/*            {t('view.crusher.motorControl.title')} */}
      {/*          </Paragraph> */}
      {/*          <Controllers> */}
      {/*            <Paragraph */}
      {/*              size={ParagraphSize.sm} */}
      {/*              weight={ParagraphWeight.bold} */}
      {/*            > */}
      {/*              {t('view.crusher.motorControl.drawer.mode')} */}
      {/*            </Paragraph> */}
      {/*            <SwitchWithText */}
      {/*              leftText={`${t('view.crusher.motorControl.manual')}`} */}
      {/*              rightText={`${t('common.auto')}`} */}
      {/*            /> */}
      {/*          </Controllers> */}
      {/*          <StopStartButtonsContainer> */}
      {/*            <Button */}
      {/*              type={ButtonType.secondary} */}
      {/*              disabled */}
      {/*              onClick={() => alert('Auto stop button was clicked!')} */}
      {/*            > */}
      {/*              {t('view.crusher.motorControl.drawer.autoStop')} */}
      {/*            </Button> */}
      {/*            <Button */}
      {/*              type={ButtonType.secondary} */}
      {/*              disabled */}
      {/*              onClick={() => alert('Auto start button was clicked!')} */}
      {/*            > */}
      {/*              {t('view.crusher.motorControl.drawer.autoStart')} */}
      {/*            </Button> */}
      {/*          </StopStartButtonsContainer> */}
      {/*          <Button */}
      {/*            disabled */}
      {/*            onClick={() => alert('Execute command button was clicked!')} */}
      {/*          > */}
      {/*            {t('common.executeCommand')} */}
      {/*          </Button> */}
      {/*        </MotorControlContainer> */}
      {/*      </Col> */}
      {/*      <Col cols={12}> */}
      {/*        <LubeTankHeaterContainer> */}
      {/*          <Paragraph */}
      {/*            weight={ParagraphWeight.bold} */}
      {/*          > */}
      {/*            {t('view.crusher.motorControl.drawer.controllersLubeTankHeater')} */}
      {/*          </Paragraph> */}
      {/*          <Controllers> */}
      {/*            <Paragraph */}
      {/*              size={ParagraphSize.sm} */}
      {/*              weight={ParagraphWeight.bold} */}
      {/*            > */}
      {/*              {t('common.status')} */}
      {/*            </Paragraph> */}
      {/*            <PowerSwitch /> */}
      {/*          </Controllers> */}
      {/*          <ExecuteCommandContainer> */}
      {/*            <Button */}
      {/*              disabled */}
      {/*              onClick={() => alert('Bowl adjustment button was clicked!')} */}
      {/*            > */}
      {/*              {t('common.executeCommand')} */}
      {/*            </Button> */}
      {/*          </ExecuteCommandContainer> */}
      {/*        </LubeTankHeaterContainer> */}
      {/*      </Col> */}
      {/*    </Row> */}
      {/*  </BorderBottom> */}
      {/*  <BorderBottom> */}
      {/*    <Row> */}
      {/*      <Col cols={12}> */}
      {/*        <LubeUnitContainer> */}
      {/*          <StatusContainer> */}
      {/*            <Paragraph */}
      {/*              weight={ParagraphWeight.bold} */}
      {/*            > */}
      {/*              {t('view.crusher.motorControl.drawer.lubeUnit')} */}
      {/*            </Paragraph> */}
      {/*            <Tag */}
      {/*              text={runningStatusToText(data.lubePumpRunningStatus)} */}
      {/*              type={ */}
      {/*              runningStatusToTagType( */}
      {/*                data.lubePumpRunningStatus, */}
      {/*                edgeDeviceOperationalStatus, */}
      {/*              ) */}
      {/*            } */}
      {/*            /> */}
      {/*          </StatusContainer> */}
      {/*          <Controllers> */}
      {/*            <Paragraph */}
      {/*              size={ParagraphSize.sm} */}
      {/*              weight={ParagraphWeight.bold} */}
      {/*            > */}
      {/*              {t('view.crusher.motorControl.drawer.currentValue')} */}
      {/*            </Paragraph> */}
      {/*            <Paragraph */}
      {/*              size={ParagraphSize.sm} */}
      {/*              weight={ParagraphWeight.bold} */}
      {/*            > */}
      {/*              {data.lubePump1RunHours === 1 */}
      {/*                ? t('dates.hour.singular') */}
      {/*                : t('dates.hour.plural', { */}
      {/*                  hours: formatNumber(data.lubePump1RunHours), */}
      {/*                })} */}
      {/*            </Paragraph> */}
      {/*          </Controllers> */}
      {/*          <Button */}
      {/*            disabled */}
      {/*            onClick={() => alert('Start button was clicked!')} */}
      {/*          > */}
      {/*            <Play /> */}
      {/*            {t('common.start')} */}
      {/*          </Button> */}
      {/*        </LubeUnitContainer> */}
      {/*      </Col> */}
      {/*      <Col cols={12}> */}
      {/*        <DischargeContainer> */}
      {/*          <StatusContainer> */}
      {/*            <Paragraph weight={ParagraphWeight.bold}> */}
      {/*              {t('view.crusher.motorControl.drawer.dischargeConveyor')} */}
      {/*            </Paragraph> */}
      {/*            <Tag */}
      {/*              text={runningStatusToText(data.dischargeRunningStatus)} */}
      {/*              type={ */}
      {/*              runningStatusToTagType( */}
      {/*                data.dischargeRunningStatus, */}
      {/*                edgeDeviceOperationalStatus, */}
      {/*              ) */}
      {/*            } */}
      {/*            /> */}
      {/*          </StatusContainer> */}
      {/*          <Controllers> */}
      {/*            <Paragraph size={ParagraphSize.sm} weight={ParagraphWeight.bold}> */}
      {/*              {t('view.crusher.motorControl.drawer.currentValue')} */}
      {/*            </Paragraph> */}
      {/*            <Paragraph size={ParagraphSize.sm} weight={ParagraphWeight.bold}> */}
      {/*              {data.dischargeRunHours === 1 */}
      {/*                ? t('dates.hour.singular') */}
      {/*                : t('dates.hour.plural', { */}
      {/*                  hours: data.dischargeRunHours, */}
      {/*                })} */}
      {/*            </Paragraph> */}
      {/*          </Controllers> */}
      {/*          <Button disabled onClick={() => alert('Stop button was clicked!')}> */}
      {/*            <Stop /> */}
      {/*            {t('common.stop')} */}
      {/*          </Button> */}
      {/*        </DischargeContainer> */}
      {/*      </Col> */}
      {/*    </Row> */}
      {/*  </BorderBottom> */}
      {/*  <Row> */}
      {/*    <Col cols={12}> */}
      {/*      <CrusherMotorContainer> */}
      {/*        <StatusContainer> */}
      {/*          <Paragraph weight={ParagraphWeight.bold}> */}
      {/*            {t('view.crusher.motorControl.drawer.crusherMotor')} */}
      {/*          </Paragraph> */}
      {/*          <Tag */}
      {/*            text={runningStatusToText(data.crusherMotor)} */}
      {/*            type={ */}
      {/*            runningStatusToTagType( */}
      {/*              data.crusherMotor, */}
      {/*              edgeDeviceOperationalStatus, */}
      {/*            ) */}
      {/*          } */}
      {/*          /> */}
      {/*        </StatusContainer> */}
      {/*        <Controllers> */}
      {/*          <Paragraph */}
      {/*            size={ParagraphSize.sm} */}
      {/*            weight={ParagraphWeight.bold} */}
      {/*          > */}
      {/*            {t('view.crusher.motorControl.drawer.currentValue')} */}
      {/*          </Paragraph> */}
      {/*          <Paragraph */}
      {/*            size={ParagraphSize.sm} */}
      {/*            weight={ParagraphWeight.bold} */}
      {/*          > */}
      {/*            {data.crusherMotor1RunHours === 1 */}
      {/*              ? t('dates.hour.singular') */}
      {/*              : t('dates.hour.plural', { */}
      {/*                hours: formatNumber(data.crusherMotor1RunHours), */}
      {/*              })} */}
      {/*          </Paragraph> */}
      {/*        </Controllers> */}
      {/*        <Button */}
      {/*          disabled */}
      {/*          onClick={() => alert('Start button was clicked!')} */}
      {/*        > */}
      {/*          <Play /> */}
      {/*          {t('common.start')} */}
      {/*        </Button> */}
      {/*      </CrusherMotorContainer> */}
      {/*    </Col> */}
      {/*    <Col cols={12}> */}
      {/*      <FeederContainer> */}
      {/*        <StatusContainer> */}
      {/*          <Paragraph weight={ParagraphWeight.bold}> */}
      {/*            {t('view.crusher.motorControl.drawer.feederConveyor')} */}
      {/*          </Paragraph> */}
      {/*          <Tag */}
      {/*            text={runningStatusToText(data.feederRunningStatus)} */}
      {/*            type={ */}
      {/*            runningStatusToTagType( */}
      {/*              data.feederRunningStatus, */}
      {/*              edgeDeviceOperationalStatus, */}
      {/*            ) */}
      {/*          } */}
      {/*          /> */}
      {/*        </StatusContainer> */}
      {/*        <Controllers> */}
      {/*          <Paragraph */}
      {/*            size={ParagraphSize.sm} */}
      {/*            weight={ParagraphWeight.bold} */}
      {/*          > */}
      {/*            {t('view.crusher.motorControl.drawer.currentValue')} */}
      {/*          </Paragraph> */}
      {/*          <Paragraph */}
      {/*            size={ParagraphSize.sm} */}
      {/*            weight={ParagraphWeight.bold} */}
      {/*          > */}
      {/*            {data.feederRunHours === 1 */}
      {/*              ? t('dates.hour.singular') */}
      {/*              : t('dates.hour.plural', { */}
      {/*                hours: formatNumber(data.feederRunHours), */}
      {/*              })} */}
      {/*          </Paragraph> */}
      {/*        </Controllers> */}
      {/*        <Button */}
      {/*          disabled */}
      {/*          onClick={() => alert('Stop button was clicked!')} */}
      {/*        > */}
      {/*          <Stop /> */}
      {/*          {t('common.stop')} */}
      {/*        </Button> */}
      {/*      </FeederContainer> */}
      {/*    </Col> */}
      {/*  </Row> */}
      {/* </ControllerModuleContainer> */}
    </Drawer>
  );
}

export { MotorControl };
