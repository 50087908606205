// Core
import React, {
  ReactNode,
  useEffect,
  useState,
} from 'react';

// Libraries
import { useTranslation } from 'react-i18next';

// Components
import { Alert } from '../Alert';
import {
  Button,
  ButtonSize,
  ButtonType,
} from '../Button';
import { Col } from '../Col';
import {
  Paragraph,
  ParagraphSize,
  ParagraphWeight,
} from '../Paragraph';
import { Row } from '../Row';
import { Close } from '../../icons';

// Component
import {
  ActionsPreviewContainer,
  Element,
  Footer,
  GradientEffect,
  Header,
  Overlay,
  PreviewContainer,
  PreviewImage,
  TitleContainer,
} from './Drawer.style';
import {
  DrawerPreviewProps,
  DrawerProps,
  DrawerSize,
  HeaderDrawerProps,
} from './Drawer.types';

function HeaderDrawer({
  alert = false,
  onClose,
  title,
}: HeaderDrawerProps): JSX.Element {
  return (
    <Row>
      <Header>
        <TitleContainer>
          <Paragraph
            size={ParagraphSize.xl}
            weight={ParagraphWeight.bold}
          >
            {title}
          </Paragraph>
          <Button
            type={ButtonType.secondary}
            onClick={onClose}
            square
            size={ButtonSize.xs}
          >
            <Close />
          </Button>
        </TitleContainer>
        {alert && (
          <Row>
            <Col>
              <Alert
                icon={alert.icon}
                testId="drawer-header-alert"
                description={alert.description}
                closable={false}
                type={alert.type}
              />
            </Col>
          </Row>
        )}
      </Header>
    </Row>
  );
}

function Preview({
  previewImage,
  primaryPreviewAction,
  secondaryPreviewAction,
  title,
}: DrawerPreviewProps): JSX.Element {
  return (
    <PreviewContainer>
      <Col cols={12}>
        <PreviewImage src={previewImage} alt={title} />
      </Col>
      <ActionsPreviewContainer cols={12}>
        {secondaryPreviewAction && (
          <Button
            type={ButtonType.secondary}
            square
            onClick={secondaryPreviewAction.onClick}
          >
            {secondaryPreviewAction.children}
          </Button>
        )}
        {primaryPreviewAction && (
          <Button
            square
            onClick={primaryPreviewAction.onClick}
            type={ButtonType.primary}
          >
            {primaryPreviewAction.children}
          </Button>
        )}
      </ActionsPreviewContainer>
    </PreviewContainer>
  );
}

function Drawer({
  alert = false,
  children,
  className,
  footer = 'default',
  onClose,
  open = false,
  preview,
  primaryAction,
  secondaryAction,
  size = DrawerSize.md,
  testId,
  title,
}: DrawerProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();

  /* ***********************************************************************************************
  **************************************** LOCAL STATE ********************************************
  *********************************************************************************************** */

  const [scrollEnd, setScrollEnd] = useState<boolean>(false);

  /* ***********************************************************************************************
  ***************************************** METHODS ************************************************
  *********************************************************************************************** */

  let renderedFooter: ReactNode;

  if (footer === 'default') {
    renderedFooter = (
      <Footer className="drawer-footer">
        {secondaryAction && (
          <Button
            onClick={secondaryAction.onClick}
            block={false}
            disabled={secondaryAction.disabled}
            type={ButtonType.link}
          >
              {secondaryAction?.children || t('layout.elements.drawer.defaultSecondaryButtonText')}
          </Button>
        )}
        {primaryAction && (
          <Button
            onClick={primaryAction.onClick}
            block
            disabled={primaryAction.disabled}
            type={ButtonType.primary}
            loading={primaryAction.loading}
            loadingText={primaryAction.loadingText}
          >
            {primaryAction?.children || t('layout.elements.drawer.defaultPrimaryButtonText')}
          </Button>
        )}
      </Footer>
    );
  } else if (footer === false) {
    renderedFooter = undefined;
  } else {
    renderedFooter = footer;
  }

  /* ***********************************************************************************************
  ************************************* COMPONENT HANDLING *****************************************
  *********************************************************************************************** */

  // Sets up a scroll listener on the element with the class '.ant-drawer-body'.
  useEffect(() => {
    const drawerElement: HTMLElement | null = document.querySelector('.ant-drawer-body');
    const handleScroll = (): void => {
      if (
        drawerElement && (
          drawerElement.scrollTop + drawerElement.offsetHeight + 5 >= drawerElement.scrollHeight
        )
      ) {
        setScrollEnd(true);
      } else {
        setScrollEnd(false);
      }
    };

    let cleanup: (() => void) | undefined;
    if (drawerElement) {
      drawerElement.addEventListener('scroll', handleScroll);
      cleanup = () => {
        drawerElement.removeEventListener('scroll', handleScroll);
      };
    }

    return cleanup;
  }, []);

  const mapWidthBySize: Record<DrawerSize, string> = {
    [DrawerSize.md]: '29%',
    [DrawerSize.lg]: '39%',
  };

  return (
    <>
      <Overlay open={open} />
      <Element
        rootClassName={className}
        data-testid={testId}
        forceRender
        title={<HeaderDrawer title={title} onClose={onClose} alert={alert} />}
        placement="right"
        closeIcon={null}
        onClose={onClose}
        open={open}
        contentWrapperStyle={{
          top: '0.75em',
          right: '0.75em',
          bottom: '0.75em',
          borderRadius: '0.25em',
          overflow: 'hidden',
          width: mapWidthBySize[size],
        }}
        footer={renderedFooter}
      >
        {preview && (
          <Preview
            previewImage={preview.previewImage}
            primaryPreviewAction={preview.primaryPreviewAction}
            secondaryPreviewAction={preview.secondaryPreviewAction}
            title={preview.title}
          />
        )}
        <div>
          {children}
        </div>
        {!scrollEnd && (
          <GradientEffect
            data-testid="gradient-effect-drawer"
            isHidden={scrollEnd}
          />
        )}
      </Element>
    </>
  );
}

export { Drawer };
