// Core
import React from 'react';

// Libraries
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Types
import { Theme } from '../../../../../types/theme';

// Components
import { Button, ButtonType } from '../../../../../layout/elements/Button';
import { Col } from '../../../../../layout/elements/Col';
import { Drawer } from '../../../../../layout/elements/Drawer';
import {
  Paragraph,
  ParagraphAlign,
  ParagraphSize,
  ParagraphWeight,
} from '../../../../../layout/elements/Paragraph';
import { Row } from '../../../../../layout/elements/Row';
import { Edit, TrashCan } from '../../../../../layout/icons';

// Media
import mockedCustomerLogo from '../../../../../media/images/logoSuperior.png';

// Plugins
import { formatNumber, timestampToDateStrText } from '../../../../../plugins/general';

// Store
import { CustomersStatsDetails, getCustomersStats } from '../../../../../store/customersStats.store';
import { setCustomer } from '../../../../../store/session.store';

// Types
import { Customer } from '../../../../../types/global';

// View
import { ReadCustomerProps } from './Read.types';
import { DrawerOperation } from '../../CustomersIndex.types';
import {
  CustomerInfoContainer,
  CustomerOverview,
  CustomerOverviewContainer,
  OverviewTextContainer,
} from './Read.style';

function ReadCustomer({
  className,
  customer,
  isDrawerOpen,
  onCloseDrawer,
  onCustomerAction,
  testId,
}: ReadCustomerProps) {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const goToCustomerScreen: NavigateFunction = useNavigate();

  // Getter
  const customerStats: CustomersStatsDetails = getCustomersStats(customer.id);

  const handleGoToCustomerScreen = async (customers: Customer): Promise<void> => {
    setCustomer(customers);
    goToCustomerScreen('/plant-management');
  };

  return (
    <Drawer
      testId={testId}
      className={className}
      title={customer.name}
      open={isDrawerOpen}
      onClose={onCloseDrawer}
      footer={(
        <Button
          block
          type={ButtonType.secondary}
          onClick={() => handleGoToCustomerScreen(customer)}
        >
          {t('view.customers.view.goToCustomer')}
        </Button>
      )}
      preview={{
        previewImage: mockedCustomerLogo,
        primaryPreviewAction: {
          onClick: () => onCustomerAction(DrawerOperation.delete, customer.id),
          children: <TrashCan />,
        },
        secondaryPreviewAction: {
          onClick: () => onCustomerAction(DrawerOperation.update, customer.id),
          children: <Edit />,
        },
      }}
    >
      <div>
        <CustomerOverviewContainer>
          <Row>
            <CustomerInfoContainer>
              <Col cols={12}>
                <Paragraph
                  color={theme.color.customersScreen.customerInfoTextColor}
                  weight={ParagraphWeight.bold}
                  size={ParagraphSize.sm}
                  testId="created-at-subtitle"
                >
                  {t('common.createdAt')}
                </Paragraph>
                {customer.createdAt && (
                  <Paragraph
                    testId="created-at"
                  >
                    {timestampToDateStrText(customer.createdAt)}
                  </Paragraph>
                )}
              </Col>
              <Col cols={12}>
                <Paragraph
                  color={theme.color.customersScreen.customerInfoTextColor}
                  weight={ParagraphWeight.bold}
                  size={ParagraphSize.sm}
                  testId="domains-subtitle"
                >
                  {t('common.domain')}
                </Paragraph>
                {customer.domains && customer.domains.map((domain: string) => (
                  <Paragraph key={domain}>
                    @
                    {domain}
                  </Paragraph>
                ))}
              </Col>
            </CustomerInfoContainer>
          </Row>

          <Row>
            <OverviewTextContainer>
              <Paragraph weight={ParagraphWeight.bold}>
                {t('view.customers.view.overview')}
              </Paragraph>
            </OverviewTextContainer>
            <CustomerOverview>
              <Row>
                <Col cols={16}>
                  <Paragraph>{t('common.activeEquipmentUsers')}</Paragraph>
                </Col>
                <Col cols={8}>
                  <Paragraph
                    align={ParagraphAlign.right}
                  >
                    {formatNumber(customerStats.stats.equipmentUnits)}
                  </Paragraph>
                </Col>
              </Row>
              <Row>
                <Col cols={16}>
                  <Paragraph>{t('common.sites')}</Paragraph>
                </Col>
                <Col cols={8}>
                  <Paragraph
                    align={ParagraphAlign.right}
                  >
                    {formatNumber(customerStats.stats.sites)}
                  </Paragraph>
                </Col>
              </Row>
              <Row>
                <Col cols={16}>
                  <Paragraph>{t('common.activeUsers')}</Paragraph>
                </Col>
                <Col cols={8}>
                  <Paragraph align={ParagraphAlign.right}>
                    {formatNumber(customerStats.stats.users)}
                  </Paragraph>
                </Col>
              </Row>
            </CustomerOverview>
          </Row>
        </CustomerOverviewContainer>
      </div>
    </Drawer>
  );
}

export { ReadCustomer };
