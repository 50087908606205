// Core
import React, { useEffect, useState } from 'react';

// Libraries
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// Plugins
import {
  isHTTPResponseError,
  RequestMethod,
  requestVantageApi,
} from '../../../../plugins/request';

// Components
import { Input, InputType } from '../../../../layout/elements/Input';
import { Button, ButtonSize } from '../../../../layout/elements/Button';
import { showToast, ToastType } from '../../../../layout/elements/Toast';

// View
import {
  Container,
  ResetPasswordContainer,
} from './ResetPassword.style';

function ResetPassword(): JSX.Element {
// Dependencies
  const { t } = useTranslation();

  /* ***********************************************************************************************
  ***************************************** LOCAL STATES *******************************************
  *********************************************************************************************** */

  interface State {
    email: string | null;
    password: string | null;
    buttonLoading: number | null | true;
    isSuccess: boolean;
  }

  const initialState: State = {
    email: null,
    password: null,
    buttonLoading: null,
    isSuccess: false,
  };

  const [state, setState] = useState<State>(initialState);

  /* ***********************************************************************************************
  ******************************************* METHODS **********************************************
  *********************************************************************************************** */

  const updateField = (field: string, value?: string | null | number): void => {
    setState((prevState: State) => ({
      ...prevState,
      [field]: value,
      error: null,
    }));
  };

  const handleReset = async (): Promise<void> => {
    setState((prevState: State) => ({
      ...prevState,
      buttonLoading: true,
    }));

    try {
      await requestVantageApi.users({
        method: RequestMethod.PUT,
        path: '/auth/password-reset',
        body: {
          email: state.email,
          password: state.password,
        },
        handleErrors: false,
      });

      // Show success toast
      showToast({
        text: `${t('view.adminActions.passwordReset.toast.success')}`,
        type: ToastType.success,
      });

      setState((prevState: State) => ({
        ...prevState,
        buttonLoading: 100,
        isSuccess: true,
      }));
    } catch (error: unknown) {
      let errorMessage: string = t('common.defaultError');

      if (isHTTPResponseError(error)) {
        if (error.message.includes('Network Error')) {
          errorMessage = t('common.networkError');
        } else if (error.response.status === 404) {
          errorMessage = t('view.adminActions.passwordReset.error');
        } else if (error.response.status === 422) {
          errorMessage = error.response.data;
        }
      }

      showToast({ text: errorMessage, type: ToastType.error });

      setState((prevState: State) => ({ ...prevState, buttonLoading: null }));
    }
  };

  const isDisabledToSubmitForm: boolean = (
    _.isNil(state.email) || _.isNil(state.password)
  );

  /* ***********************************************************************************************
  ************************************** COMPONENT HANDLING ****************************************
  *********************************************************************************************** */

  // Effect to reset fields after success
  useEffect((): void => {
    setState((prevState: State) => ({
      ...prevState,
      buttonLoading: null,
      isSuccess: false,
    }));
  }, [state.isSuccess]);

  return (
    <Container>
      <ResetPasswordContainer>
        <Input
          label={`${t('common.email')}`}
          testId="input-email"
          onChange={(value) => updateField('email', value)}
          value={state.email}
          onPressEnter={() => {
            const inputs = Array.from(document.querySelectorAll('input'))[1];
            if (inputs) inputs.focus();
          }}
        />
        <Input
          label={`${t('common.password')}`}
          onChange={(value) => updateField('password', value)}
          testId="input-password"
          type={InputType.password}
          value={state.password}
          onPressEnter={handleReset}
        />

        <Button
          size={ButtonSize.lg}
          loading={state.buttonLoading}
          disabled={isDisabledToSubmitForm}
          loadingText={`${t('view.adminActions.passwordReset.loadingText')}`}
          onClick={handleReset}
          testId="reset-password-button"
        >
          {t('view.adminActions.passwordReset.button')}
        </Button>
      </ResetPasswordContainer>

    </Container>
  );
}

export { ResetPassword };
