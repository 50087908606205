// Core
import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 44px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0 20px;
  gap: 20px;
`;
