/* eslint-disable @typescript-eslint/indent */

/* Note: please be careful with indentation, we have the rule disabled because of
*  eslint not being able to properly indent js inside styled components
*/

// Core
import styled from 'styled-components';

// Component
import { SelectProps, SelectSizeType } from './Select.types';

const mapLabelPosition: Partial<Record<SelectSizeType, string>> = {
  [SelectSizeType.md]: '2px',
  [SelectSizeType.lg]: '3px',
};

export const FloatingLabel = styled.label<Pick<SelectProps, 'value' | 'size' | 'disabled'>>`
  position: absolute;
  top: ${({ value, size }) => (value ? mapLabelPosition[size as SelectSizeType] : '8px')};
  visibility: ${({ value }) => (value ? 'visible' : 'hidden')};
  font-size: ${({ value }) => (value ? '12px' : '16px')};
  color: ${({ theme, disabled }) => (disabled
    ? theme.color.select.disabledTextColorLabel
    : theme.color.select.textColorLabel)
  };
  font-family: ${({ theme }) => theme.font.medium};
  margin-top: 4px;
  pointer-events: none;
  transition: ease-in-out .3s, ease-out 0.1s;
  user-select: none;
`;

export const Container = styled.div<Pick<SelectProps, 'disabled' | 'size' | 'label' | 'prefix'>>`
  position: relative;
  flex: 1;
  display: flex;
  transition: 0.4s ease-in;
  user-select: none;

  .placeholder-text {
    opacity: 0.8;
    transition: opacity 0.5s ease-in-out, color 0.5s ease-in-out;
  };

  .placeholder-text.hovered {
    opacity: 1;
    transition: opacity 0.4s ease-in, color 0.4s ease-in-out;
    color: ${({ theme }) => theme.color.select.textColorPlaceholderHover};
  };

  .ant-select {
    width: 100%;
  };
  
  /* *********** PADDING *********** */
  .ant-select-selector {
    padding: 0 16px 0 12px !important;
    border: none;
  };

  /* *********** MODE TAGS STYLE *********** */

  .ant-select-selection-overflow-item > span {
    margin-top: 2px !important;
  };

  /* *********** TAGS SIZE = LG *********** */
  
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 32px;
    align-items: center;
    padding-left: 10px;
  };

  /* *********** TAGS SIZE = MD *********** */
  
  .ant-select-multiple .ant-select-selection-item {
    height: 26px;
    align-items: center;
    padding-left: 8px !important;
    margin-inline-end: 8px !important;
  };
  
  .ant-select-selection-overflow-item {
    height: 28px;
    align-items: center;
  };

  /* *********** TAGS SIZE = SM *********** */
  
  .ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 22px;
    align-items: center;
  };
  
  .ant-select-multiple.ant-select-sm.ant-select-show-arrow .ant-select-selector,
  .ant-select-multiple.ant-select-sm.ant-select-allow-clear .ant-select-selector {
    height: 32px;
  };

  .ant-select-selection-item-content {
    font-size: 12px;
  };
  
  /* *********** SELECT *********** */
  .ant-select {
    flex: 1;
    display: block;
    background-color: ${({
      theme,
      disabled,
    }) => (
      disabled
        ? theme.color.select.backgroundColorSelectDisabled
        : theme.color.select.backgroundColorSelect
    )};
  };
  
  /* *********** ARROW ICON *********** */
  .ant-select-arrow {
    padding-right: 4px;
    border: none;
    color: ${({
      theme,
      disabled,
    }) => (
      disabled
        ? theme.color.select.caretDownColorDisabled
        : theme.color.select.caretDownColor
    )};
  };
  
  /* *********** CLEAR ICON *********** */
  .ant-select-clear {
    margin-right: 4px;
    color: ${({ theme }) => (theme.color.select.clearIconColor)};

    /* *********** HOVER *********** */
    :hover {
      color: ${({ theme }) => (theme.color.select.clearIconColorHover)};
      transition: 0.3s ease-in;
    };
  };
  
  /* *********** HOVER *********** */
  &:hover {
    ${({ disabled, theme }) => (!disabled ? `
      .ant-select:hover .ant-select-selection-placeholder {
        color: ${theme.color.select.textColorPlaceholderHover};
        transition: .8s ease-in;
      };
    ` : 'inherit')};
  };
  
  /* *********** PLACEHOLDER *********** */
  .ant-select-selection-placeholder {
    margin-left: ${({ size }) => (size === SelectSizeType.sm ? '7px' : '3px')};
    font-size: 16px;
    color: ${({
      theme,
      disabled,
    }) => (
      disabled
        ? theme.color.select.textColorPlaceholderDisabled
        : theme.color.select.textColorPlaceholder
    )};
    font-family: ${({ theme }) => theme.font.regular};
  };

  /* *********** FONT STYLE *********** */
  .ant-select-selection-item {
    font-size: 16px;
    margin-top: ${({ size, label }) => (size !== SelectSizeType.sm && label ? '7px' : '0')};
    font-family: ${({ theme }) => theme.font.regular};
    color: ${({
      theme,
      disabled,
    }) => (
      disabled
        ? theme.color.select.textColorPlaceholderDisabled
        : theme.color.select.textColorSelectedItem
    )};
  };

  .ant-select-selection-search {
    margin: 0 !important;
  };
`;

export const SupportingTextContainer = styled.div<Pick<SelectProps, 'disabled'>>`
  padding-top: 6px;
  margin-left: 16px;
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
`;

export const PrefixContainer = styled.div<Pick<SelectProps, 'disabled'>>`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({
    theme,
    disabled,
  }) => (
    disabled
      ? theme.color.select.prefixBackgroundColorDisabled
      : theme.color.select.prefixBackgroundColor
  )};
  border-radius: 4px 0 0 4px;
  padding-left: 16px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
  
  > svg {
    height: 16px;
    width: 16px;
    color: ${({ theme, disabled }) => (
      disabled
        ? theme.color.select.prefixIconColorDisabled
        : theme.color.select.prefixIconColor
    )};
  };
`;
