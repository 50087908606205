// Core
import styled from 'styled-components';

export const UpdateContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.customersUpdate.borderColor};  
`;

export const Image = styled.img`
  width: 50%;
  height: 200px;
  object-fit: contain;
`;
