// Core
import React from 'react';

// Libraries
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Plugins
import { lastOperationalUnitData } from '../../../../plugins/crusher';
import { formatNumber } from '../../../../plugins/general';

// Types
import { EquipmentUnitDataCrusher } from '../../../../types/crusher';
import { Theme } from '../../../../types/theme';

// Components
import { Paragraph, ParagraphWeight } from '../../../../layout/elements/Paragraph';
import {
  Title,
  TitleSize,
  TitleWeight,
} from '../../../../layout/elements/Title';

// View
import {
  CSSettingsContainer,
  CSSettingsContent,
  CSSettingsUnitContainer,
  CSSettingsValueContainer,
} from './CSSettingsModule.style';
import { CrusherCSSettingsModuleProps } from './CSSettingsModule.types';

function CSSettingsModule({
  className,
  operationalData,
  testId,
}: CrusherCSSettingsModuleProps): JSX.Element {
  // Dependencies
  const { t } = useTranslation();
  const theme: Theme = useTheme();

  const lastUnitData: EquipmentUnitDataCrusher | null = lastOperationalUnitData(operationalData);

  return (
    <CSSettingsContainer
      data-testid={testId}
      className={className}
    >
      <Paragraph weight={ParagraphWeight.bold}>
        {t('view.crusher.csSetting')}
      </Paragraph>
      <CSSettingsContent>
        <CSSettingsValueContainer>
          <Title
            size={TitleSize.xxs}
            weight={TitleWeight.medium}
          >
            {formatNumber(lastUnitData?.csSetting, 2)}
          </Title>
        </CSSettingsValueContainer>
        <CSSettingsUnitContainer>
          <Paragraph
            color={theme.color.crusherDashboard.csSettings.unitTextColor}
          >
            {/* @TODO: apply the unit in the future and remove this hardcoded */}
            mm
          </Paragraph>
        </CSSettingsUnitContainer>
      </CSSettingsContent>
    </CSSettingsContainer>
  );
}

export { CSSettingsModule };
