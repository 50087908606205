// Core
import React from 'react';

// Layout
import { AlertProps } from '../Alert';

type ShowAlert = {
  description: AlertProps['description'];
  icon: AlertProps['icon'];
  type: AlertProps['type'];
};

export interface DrawerAction {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLElement>;
  loading?: number | true | null | undefined;
  loadingText?: string;
}

export interface HeaderDrawerProps {
  alert?: ShowAlert | false;
  onClose: () => void;
  title: string;
}

export interface DrawerPreviewProps {
  previewImage?: string;
  primaryPreviewAction?: DrawerAction;
  secondaryPreviewAction?: DrawerAction;
  title?: DrawerProps['title'];
}

export enum DrawerSize {
  md = 'md',
  lg = 'lg',
}

export interface DrawerProps {
  alert?: ShowAlert | false;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  footer?: React.ReactElement | false | 'default';
  onClose: () => void;
  open: boolean;
  preview?: DrawerPreviewProps | false;
  primaryAction?: DrawerAction;
  secondaryAction?: DrawerAction;
  size?: DrawerSize,
  testId?: string;
  title: string;
}
